import { Backdrop, Box, Fade, Modal } from "@mui/material";
import React from "react";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid rgba(32,101,209,1)",
  boxShadow: 24,
  p: 4,
};

const OrderStockModal = ({ open, handleClose }) => {
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
        onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
           jj
        </Box>
      </Fade>
    </Modal>
  );
};

export default OrderStockModal;
