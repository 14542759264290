import { Helmet } from "react-helmet-async";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

// @mui
import {
  Stack,
  Paper,
  Avatar,
  Grid,
  Container,
  Typography,
  Box,
  Tooltip,
  Chip,
} from "@mui/material";
import axios from "axios";
// components
import {
  DataGrid,
  GridToolbarQuickFilter,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { sentenceCase } from "change-case";
import { isLoading, openSnackbar } from "../redux/action/defaultActions";
import { baseUrl } from "../utils/apiUrl";
import { fDateTime } from "../utils/formatTime";

export default function ReturnedCup() {
  const dispatch = useDispatch();
  const [returnedRecords, setReturnedRecords] = useState([]);

  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState("asc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState("name");

  const [filterName, setFilterName] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(5);

  // customize filter tool
  function CustomizedToolbarLayout() {
    return (
      <Grid container alignItems={"center"}>
        <Grid item xs={6}>
          <Box width="100%" sx={{ p: 2 }}>
            <GridToolbarQuickFilter
              fullWidth
              variant="outlined"
              size="small"
              sx={{
                "& .MuiOutlinedInput-root": {
                  color: "rgba(158, 158, 158, 1)",
                  borderRadius: "10px",
                  borderColor: "rgba(158, 158, 158, 1)",
                },
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={6} display={"flex"} justifyContent={"end"}>
          <CustomToolbar />
        </Grid>
      </Grid>
    );
  }

  // custome toolbar
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        {/* <GridToolbarColumnsButton /> */}
        <GridToolbarFilterButton />
        {/* <GridToolbarDensitySelector /> */}
        {/* <GridToolbarExport /> */}
      </GridToolbarContainer>
    );
  }

  // data-grid coulumn defenition
  const columns = [
    { field: "index", headerName: "#", width: 30 },
    {
      field: "customer",
      headerName: "Customer",
      width: 160,
      // editable: true,
      renderCell: (value) => {
        return (
          <Stack
            direction={"row"}
            alignItems={"center"}
            spacing={1.5}
            textOverflow={"ellipses"}
          >
            <Avatar
              alt={value?.row?.customer?.name}
              src={value?.row?.customer?.profileUrl}
            />
            <Tooltip title={value?.row?.customer?.name}>
              <Typography
                variant="h6"
                color={"primary"}
                fontFamily={"monospace"}
                component={"span"}
              >
                {value?.row?.customer?.name?.length <= 8
                  ? value?.row?.customer?.name
                  : value?.row?.customer?.name.substring(0, 8)}
                {"..."}
              </Typography>
            </Tooltip>
          </Stack>
        );
      },
    },
    {
      field: "returnedCup",
      headerName: "Cup Details",
      width: 220,
      editable: false,
      renderCell: (value) => {
        return (
          <>
            <Stack
              direction={"row"}
              alignItems={"center"}
              spacing={1.5}
              textOverflow={"ellipses"}
            >
              <Avatar
                alt={value?.row?.returnedCup?.size}
                src={value?.row?.returnedCup?.cupImages[0]?.url}
              />
              <Typography variant="h6" component={"label"}>
                {value?.row?.returnedCup?.cupType}
                <Typography variant="p" component={"span"}>
                  {` (${value?.row?.returnedCup?.cupSize})`}
                </Typography>
              </Typography>
            </Stack>
          </>
        );
      },
    },
    {
      field: "returnedVendor",
      headerName: "Return To Store",
      width: 220,
      editable: false,
      renderCell: (value) => {
        return (
          <>
            <Typography>{value?.row?.returnedVendor?.name}</Typography>
          </>
        );
      },
    },
    {
      field: "fromVendor",
      headerName: "Bought At Store",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 160,
      renderCell: (value) => {
        return (
          <>
            <Typography>{value?.row?.fromVendor?.name}</Typography>
          </>
        );
      },
    },
    {
      field: "orderTime",
      headerName: "Order Date",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 160,
      renderCell: (value) => {
        return <>{fDateTime(value?.row?.orderTime)}</>;
      },
    },
    {
      field: "orderStatus",
      headerName: "Status",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 160,
      renderCell: (value) => {
        return (
          <>
            <Chip
              size="small"
              color={
                (value?.row?.orderStatus.toLowerCase() !== "success" &&
                  "error") ||
                "success"
              }
              label={sentenceCase(value?.row?.orderStatus)}
            />
          </>
        );
      },
    },
  ];

  const getReturnedRecord = async () => {
    dispatch(isLoading(true));
    try {
      const res = await axios.get(`${baseUrl}/return/all/cups`);
     
      if (res.status === 200 && res.data.returned_Cups) {
        setReturnedRecords(res?.data?.returned_Cups);
        dispatch(openSnackbar(res?.data?.message, "success"));
      } else if (res.status === 200 && res?.data?.message) {
        dispatch(openSnackbar(res?.data?.message, "warning"));
      }
      dispatch(isLoading(false));
    } catch (error) {
      if (error?.response?.data?.message) {
        dispatch(openSnackbar(error?.response?.data?.message, "error"));
      } else {
        dispatch(openSnackbar("something went wrong", "error"));
      }
      dispatch(isLoading(false));

      console.log("error=>", error);
    }
  };

  useEffect(() => {
    getReturnedRecord();
  }, []);

  const allRowDataIncludingIndex = returnedRecords?.map((row, index) => ({
    ...row,
    index: index + 1,
  }));

  return (
    <>
      <Helmet>
        <title> Vendor | CHIPT | Return </title>
      </Helmet>

      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Returned
          </Typography>
          {/* <Button
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            New Vendor
          </Button> */}
        </Stack>

        {/* <Card>
          <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={USERLIST.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      const {
                        id,
                        name,
                        role,
                        status,
                        company,
                        avatarUrl,
                        isVerified,
                      } = row;
                      const selectedUser = selected.indexOf(name) !== -1;

                      return (
                        // <TableRow
                        //   hover
                        //   key={id}
                        //   tabIndex={-1}
                        //   role="checkbox"
                        //   selected={selectedUser}
                        // >
                        //   <TableCell padding="checkbox">
                        //     <Checkbox
                        //       checked={selectedUser}
                        //       onChange={(event) => handleClick(event, name)}
                        //     />
                        //   </TableCell>

                        //   <TableCell component="th" scope="row" padding="none">
                        //     <Stack
                        //       direction="row"
                        //       alignItems="center"
                        //       spacing={2}
                        //     >
                        //       <Avatar alt={name} src={avatarUrl} />
                        //       <Typography variant="subtitle2" noWrap>
                        //         {name}
                        //       </Typography>
                        //     </Stack>
                        //   </TableCell>

                        //   <TableCell align="left">{company}</TableCell>

                        //   <TableCell align="left">{role}</TableCell>

                        //   <TableCell align="left">
                        //     {isVerified ? "Yes" : "No"}
                        //   </TableCell>

                        //   <TableCell align="left">
                        //     <Label
                        //       color={
                        //         (status === "banned" && "error") || "success"
                        //       }
                        //     >
                        //       {sentenceCase(status)}
                        //     </Label>
                        //   </TableCell>

                        //   <TableCell align="right">
                        //     <IconButton
                        //       size="large"
                        //       color="inherit"
                        //       onClick={handleOpenMenu}
                        //     >
                        //       <Iconify icon={"eva:more-vertical-fill"} />
                        //     </IconButton>
                        //   </TableCell>
                        // </TableRow>
                        <></>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: "center",
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete
                            words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={USERLIST.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card> */}

        <Box sx={{ height: 400, width: "100%" }} component={Paper}>
          {returnedRecords && returnedRecords.length > 0 ? (
            <DataGrid
              rows={allRowDataIncludingIndex}
              columns={columns}
              getRowId={(row) => row?._id}
              slots={{ toolbar: CustomizedToolbarLayout }}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 5,
                  },
                },
              }}
              pageSizeOptions={[5]}
              sx={{
                "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                  outline: "none !important",
                },
              }}
              // checkboxSelection
              disableRowSelectionOnClick
            />
          ) : (
            "No Records Found"
          )}
        </Box>
      </Container>

      {/* <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            "& .MuiMenuItem-root": {
              px: 1,
              typography: "body2",
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem>
          <Iconify icon={"eva:edit-fill"} sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        <MenuItem sx={{ color: "error.main" }}>
          <Iconify icon={"eva:trash-2-outline"} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover> */}
    </>
  );
}
