import {
  Avatar,
  Card,
  CardActions,
  CardContent,
  Container,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import Iconify from "../components/iconify/Iconify";

const CupsPortfoilo = () => {
  const navigate = useNavigate();
  return (
    <>
      <Helmet>
        <title> Dashboard: Cups | NFC | CHIPT </title>
      </Helmet>

      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Cups
          </Typography>
        </Stack>
        <Grid container spacing={2.5} justifyContent={"space-between"}>
          <Grid
            xs={12}
            item
            md={5}
            // sx={{
            //   transition: "ease-in all 300ms",
            //   cursor: "pointer",
            //   "&:hover": { scale: "1.075" },
            // }}
            // onClick={() => {
            //   navigate("/returned");
            // }}
          >
            <Card sx={{ bgcolor: "rgba(124,87,61,1)" }}>
              {/* rgb(255,247,205) */}
              <CardContent>
                {" "}
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  color={"white"}
                >
                  <Avatar
                    src="/assets/icons/navbar/ic_coffee_inventory.svg"
                    alt="Total Coffee"
                    sx={{
                      "& svg": {
                        color: "white",
                        fill:{color: "white !important"},
                      },
                      // filter:
                      //   "invert(320%) sepia(25%) saturate(2%) hue-rotate(0deg) brightness(100%) contrast(0.05%)",
                    }}
                  />
                  {/* raphael:coffee */}
                  {/* <IconButton sx={{fontSize:"40px"}} >
                    <Iconify icon="raphael:coffee" />
                  </IconButton> */}
                  <Typography
                    variant="h4"
                    component={"div"}
                    fontFamily={"monospace"}
                    color={"white"}
                  >
                    Total Cups
                  </Typography>
                  <Typography
                    variant="h4"
                    component={"span"}
                    fontFamily={"monospace"}
                    color={"white"}
                  >
                    25k
                  </Typography>
                </Stack>
              </CardContent>
            </Card>
          </Grid>
          <Grid
            xs={12}
            item
            md={5}
            sx={{
              transition: "ease-in all 300ms",
              cursor: "pointer",
              "&:hover": { scale: "1.075" },
            }}
            onClick={() => {
              navigate("/inventory");
            }}
          >
            <Card sx={{ bgcolor: "rgba(245,239,235,1)" }}>
              {/* rgb(255,247,205) */}
              <CardContent>
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Avatar
                    src="/assets/icons/navbar/ic_coffee_inventory.svg"
                    alt="Total Coffee"
                  />
                  <Typography
                    variant="h4"
                    component={"div"}
                    fontFamily={"monospace"}
                    color={"warning"}
                  >
                    Cups In Stock
                  </Typography>
                  <Typography
                    variant="h4"
                    component={"span"}
                    fontFamily={"monospace"}
                    color={""}
                  >
                    25k
                  </Typography>
                </Stack>
              </CardContent>
            </Card>
          </Grid>
          <Grid
            xs={12}
            item
            md={5}
            sx={{
              transition: "ease-in all 300ms",
              cursor: "pointer",
              "&:hover": { scale: "1.075" },
            }}
            onClick={() => {
              navigate("/returned");
            }}
          >
            <Card sx={{ bgcolor: "rgb(245,239,235,1)" }}>
              {/* rgb(255,247,205) */}
              <CardContent>
                {" "}
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Avatar
                    src="/assets/icons/navbar/ic_coffee_inventory.svg"
                    alt="Total Coffee"
                  />
                  <Typography
                    variant="h4"
                    component={"div"}
                    fontFamily={"monospace"}
                    color={"warning"}
                  >
                    Returned
                  </Typography>
                  <Typography
                    variant="h4"
                    component={"span"}
                    fontFamily={"monospace"}
                    color={""}
                  >
                    25k
                  </Typography>
                </Stack>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default CupsPortfoilo;
