import React, { useState } from "react";
import {
  Container,
  Stack,
  Box,
  Typography,
  TextField,
  Button,
} from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

import { useDispatch } from "react-redux";

import { useNavigate } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../../utils/apiUrl";
import { isLoading, openSnackbar } from "../../redux/action/defaultActions";

const VerifyEmailToGetResetOtp = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [email, setEmail] = useState("");

  const handleVerifyEmail = async (e) => {
    e.preventDefault();
    dispatch(isLoading(true));
    try {
      const res = await axios.post(`${baseUrl}/account/otp/forgot/password`, {
        email,
      });

      dispatch(isLoading(false));
      navigate("/forget-password/otp-verification", {
        state: { email },
      });
      dispatch(openSnackbar(res?.data?.message, "success"));
    } catch (error) {
      if (error.response.data.message) {
        dispatch(openSnackbar(error.response.data.message, "error"));
      } else {
        dispatch(openSnackbar("something went wrong", "error"));
      }
      dispatch(isLoading(false));
    }
  };

  return (
    <>
      <Container>
        <Box
          display={"flex"}
          flexDirection={"row"}
          alignContent={"center"}
          justifyContent={"center"}
          height={"100vh"}
        >
          <Box
            width={{ md: "40%", sm: "60%", xs: "90%" }}
            alignSelf={"center"}
            // border={"1px solid lightgray"}
            // p={1}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              mb={2}
            >
              <Stack
                direction={"row"}
                spacing={1}
                sx={{ cursor: "pointer", "&>hover": { color: "red" } }}
                mb={1}
                onClick={() => navigate(-1)}
              >
                <KeyboardBackspaceIcon />{" "}
                <Typography variant="body2" component={"span"}>
                  Back
                </Typography>
              </Stack>
            </Stack>
            <Stack direction={"row"}>
              <Typography color={"primary"} variant="h6" component="h2">
                Enter your registered email
              </Typography>
            </Stack>
            <Typography>
              An otp will be sent to your registered email, and this otp is
              valid for 15 minutes. Do not leave this page.
            </Typography>
            <Stack component={"form"} spacing={2} onSubmit={handleVerifyEmail}>
              <TextField
                placeholder="Enter your registered email"
                label="Email"
                fullWidth
                sx={{ mt: 2 }}
                size="small"
                name="email"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <Button
                type="submit"
                variant="contained"
                fullWidth
                color="primary"
              >
                proceed
              </Button>
            </Stack>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default VerifyEmailToGetResetOtp;
