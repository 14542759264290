import {
  Avatar,
  Button,
  Chip,
  Container,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import { baseUrl } from "../../../utils/apiUrl";
import ConfirmActionModal from "../../../components/modals/ConfirmActionModal";
import { isLoading, openSnackbar } from "../../../redux/action/defaultActions";

const ConfirmOrderScreen = () => {
  const { orderId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openConirmationModal, setOpenConirmationModal] = useState(false);

  const [orderDetails, setOrderDetails] = useState(null);

  const toggleConfirmnationModal = () =>
    setOpenConirmationModal(!openConirmationModal);

  const handleConfirm = async () => {
    dispatch(isLoading(true));
    try {
      const res = await axios.get(`${baseUrl}/store/stock/update/${orderId}`);

      dispatch(openSnackbar("order stock delivery confirmed", "success"));

      toggleConfirmnationModal();
      navigate(-1);
      dispatch(isLoading(false));
    } catch (error) {
      console.log({ error });
      if (error.response.data.message) {
        dispatch(openSnackbar(error.response.data.message, "error"));
      } else {
        dispatch(openSnackbar("something went wrong", "error"));
      }
      dispatch(isLoading(false));
    }
  };

  // get order detail
  const getOrderDetail = async () => {
    dispatch(isLoading(true));
    try {
      const res = await axios.get(`${baseUrl}/store/stock/request/${orderId}`);

      setOrderDetails(res?.data?.stockRequest);
      dispatch(isLoading("success", "success"));
      dispatch(isLoading(false));
    } catch (error) {
      if (error?.response?.data?.message) {
        dispatch(openSnackbar(error?.response?.data?.message));
      }
      dispatch("something went wrong", "error");
      dispatch(isLoading(false));
      console.log("error=>", error);
    }
  };

  //
  useEffect(() => {
    getOrderDetail();
  }, []);

  return (
    <>
      <Helmet>
        <title> Dashboard : Order | Confirm | CHIPT </title>
      </Helmet>

      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={2}
        >
          <Typography variant="h4" gutterBottom>
            Order Preview
          </Typography>
          <Stack
            direction={"row"}
            spacing={1}
            sx={{ cursor: "pointer", "&>hover": { color: "red" } }}
            mb={1}
            onClick={() => navigate(-1)}
          >
            <KeyboardBackspaceIcon />{" "}
            <Typography variant="body2" component={"span"}>
              Back
            </Typography>
          </Stack>
        </Stack>
        <Stack>
          <Typography>Order Id : {orderId}</Typography>
          <Stack mt={2}>
            <Typography
              component={"p"}
              mb={2}
              fontSize={"16px"}
              variant="body2"
            >
              we sent an email to{" "}
              <span style={{ fontWeight: "bold" }}>
                {orderDetails?.vendor?.email}
              </span>{" "}
              with your order information and receipt. If the email hasn't
              arrived within two minutes, please check your spam folder to see
              the email.
            </Typography>
            <Grid container>
              <Grid item xs={12} md={6} p={2} border={"0.5px solid lightgrey"}>
                <LocationOnIcon sx={{ color: "#1896E7" }} />
                <Typography
                  variant="h5"
                  fontFamily={"monospace"}
                  component={"address"}
                >
                  Shipping Address
                </Typography>
                <Stack mt={1}>
                  <Typography
                    variant="body1"
                    fontWeight={"400"}
                    component={"article"}
                  >
                    {orderDetails?.vendor?.name}
                  </Typography>
                  <Typography variant="body2" component={"p"}>
                    {`${orderDetails?.shippingAddress?.plotnumber} ${orderDetails?.shippingAddress?.address} ${orderDetails?.shippingAddress?.city} , ${orderDetails?.shippingAddress?.state} . ${orderDetails?.shippingAddress?.zipCode} , ${orderDetails?.shippingAddress?.country}`}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={12} md={6} p={2} border={"0.5px solid lightgrey"}>
                <LocalShippingIcon sx={{ color: "#1896E7" }} />
                <Typography
                  variant="h5"
                  fontFamily={"monospace"}
                  component={"address"}
                >
                  Shipped From
                </Typography>
                <Stack mt={1}>
                  <Typography
                    variant="body1"
                    fontWeight={"400"}
                    component={"article"}
                  >
                    Chipt NFC Head Office
                  </Typography>
                  <Typography variant="body2" component={"p"}>
                    544 Verginia Ave , Chicago , 45868 , US
                  </Typography>
                </Stack>
              </Grid>
            </Grid>

            <Grid container mt={2} rowGap={2}>
              <Grid
                item
                xs={12}
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"space-between"}
              >
                <Typography variant="subtitle1" mb={2} component={"article"}>
                  Order List
                </Typography>
                {orderDetails?.approvalStatus === "STATUS_ACCEPTED" ? (
                  orderDetails?.approvalStatus === "STATUS_ACCEPTED" &&
                  orderDetails?.requestStatus === "DELIVERED" ? (
                    <>
                      {orderDetails.isDeliveryConfirm ? (
                        <Chip
                          variant="contained"
                          color="success"
                          label="added to stock"
                        />
                      ) : (
                        <Button
                          variant="outlined"
                          onClick={toggleConfirmnationModal}
                          size="small"
                        >
                          Confirm Order
                        </Button>
                      )}
                    </>
                  ) : (
                    <>
                      {orderDetails?.approvalStatus === "STATUS_ACCEPTED" &&
                      orderDetails?.requestStatus === "SHIPPED" ? (
                        <Chip
                          variant="contained"
                          color="info"
                          label={orderDetails?.requestStatus}
                        />
                      ) : orderDetails?.approvalStatus === "STATUS_ACCEPTED" &&
                        orderDetails?.requestStatus === "DELIVERED" ? (
                        <Chip
                          variant="contained"
                          color="success"
                          label={orderDetails?.requestStatus}
                        />
                      ) : (
                        <Chip
                          variant="contained"
                          color="primary"
                          label={orderDetails?.requestStatus}
                        />
                      )}
                    </>
                  )
                ) : (
                  <>
                    <Chip
                      variant="contained"
                      color="error"
                      label={orderDetails?.requestStatus}
                    />
                  </>
                )}
                {/* {orderDetails?.requestStatus === "REJECTED" ? (
                  <Chip variant="contained" color="error" label="Rejected" />
                ) : orderDetails?.requestStatus === "PROCESSING" ? (
                  <Chip variant="contained" color="warning" label="Pending" />
                ) : orderDetails?.requestStatus === "DELIVERED" ? (
                  <Chip variant="contained" color="success" label="Delivered" />
                ) : (
                  
                  <Button
                    variant="contained"
                    onClick={toggleConfirmnationModal}
                    size="small"
                  >
                    Confirm Order
                  </Button>
                )} */}
              </Grid>
              <Grid item xs={12}>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell component={"th"} scope="row">
                          Sr.No
                        </TableCell>
                        <TableCell component={"th"}>Cup Type</TableCell>
                        <TableCell component={"th"}>Cup Size</TableCell>
                        <TableCell component={"th"}>No Of Cups</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {orderDetails?.cupsRequested?.map((request, rowIndex) => {
                        return (
                          <TableRow key={rowIndex}>
                            <TableCell>{rowIndex + 1}</TableCell>
                            <TableCell> {request?.cupType}</TableCell>
                            <TableCell> {request?.cupSize}</TableCell>
                            <TableCell>{request?.numberOfCups}</TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Stack>
        </Stack>
      </Container>
      <ConfirmActionModal
        open={openConirmationModal}
        handleClose={toggleConfirmnationModal}
        handleAction={handleConfirm}
      />
    </>
  );
};

export default ConfirmOrderScreen;
