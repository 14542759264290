import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// @mui
import {
  Link,
  Stack,
  IconButton,
  InputAdornment,
  TextField,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components

import axios from "axios";
import { firtTimeLogin, login } from "../../../redux/action/adminActions";
import {
  isLoading,
  openSnackbar,
  toggleGenerateOTPModalOpen,
} from "../../../redux/action/defaultActions";
import Iconify from "../../../components/iconify";
import { baseUrl } from "../../../utils/apiUrl";

// ----------------------------------------------------------------------

export default function LoginForm() {
  const nfcTngVendor  = useSelector((state) => state.nfcTngVendor);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [showPassword, setShowPassword] = useState(false);

  const [loginDetails, setLoginDetails] = useState({});

  const [firstTimeLogin, setFirstTimeLogin] = useState(false);

  const handleChangeLoginValues = (e) => {
    setLoginDetails({ ...loginDetails, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(isLoading(true));
    try {
      const res = await axios.post(`${baseUrl}/account/signin`, {
        email: loginDetails?.email,
        password: loginDetails?.password,
      });

      console.log("loginInfo=>", res);
      // if (res?.data?.isActive) {
      //   dispatch(
      //     firtTimeLogin({ email: loginDetails?.email, firstTimeLogin: true })
      //   );
      //   navigate("/first/login", { replace: true });
      // } else if (res.data.isActive && res.data.isVerified) {
      //   dispatch(
      //     login({
      //       email: loginDetails?.email,
      //       accountCreated: true,
      //       firstTimeLogin: false,
      //     })
      //   );
      //   navigate("/", { replace: true });
      //   dispatch(openSnackbar("Welcome Back", "success"));
      // } else if (res.data.isActive && !res.data.isVerified) {
      //   dispatch(
      //     login({
      //       email: loginDetails?.email,
      //       accountCreated: false,
      //       firstTimeLogin: false,
      //     })
      //   );
      //   navigate("/create-profile", { replace: true });
      // } else {
      //   dispatch(
      //     login({
      //       email: loginDetails?.email,
      //       accountCreated: true,
      //       firstTimeLogin: false,
      //     })
      //   );
      //   navigate("/", { replace: true });
      // }
      if (res.data.isActive && res.data.isVerified) {
        dispatch(
          login({
            email: loginDetails?.email,
            accountCreated: true,
            firstTimeLogin: false,
          })
        );
        navigate("/", { replace: true });
      } else if (res.data.isActive && !res.data.isVerified) {
        dispatch(
          login({
            email: loginDetails?.email,
            accountCreated: false,
            firstTimeLogin: false,
          })
        );
        navigate("/create-profile", { replace: true });
      } else if (!res.data.isActive && !res.data.isVerified && res.data.firstTime) {
        dispatch(
          firtTimeLogin({ email: loginDetails?.email, firstTimeLogin: true })
        );
        navigate("/first/login", { replace: true });
      }
      dispatch(isLoading(false));
      // }
    } catch (error) {
      console.log({ error });
      if (error.response.data.message) {
        dispatch(openSnackbar(error.response.data.message, "error"));
      }
      dispatch(isLoading(false));
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            name="email"
            label="Email address"
            value={loginDetails?.email ? loginDetails?.email : ""}
            onChange={handleChangeLoginValues}
          />

          <TextField
            name="password"
            label="Password"
            value={loginDetails?.password ? loginDetails?.password : ""}
            onChange={handleChangeLoginValues}
            type={showPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    <Iconify
                      icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Stack>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ my: 2 }}
        >
          {/* <FormControlLabel
            control={
              <Checkbox
                checked={firstTimeLogin}
                onClick={() => setFirstTimeLogin(!firstTimeLogin)}
              />
            }
            label="First Time Login"
          /> */}
          {/* <Checkbox name="remember" label="Remember me" /> */}

          <Link
            variant="subtitle2"
            underline="hover"
            sx={{ cursor: "pointer" }}
            onClick={() => navigate("/forget-password")}
          >
            Forgot password ?
          </Link>
        </Stack>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          // onClick={handleClick}
        >
          Login
        </LoadingButton>
      </form>
    </>
  );
}
