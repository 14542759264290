import React, { useEffect, useState } from "react";
import { Box, Button,Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { openSnackbar } from "../../redux/action/defaultActions";

const NFCScan = () => {
  const [nfcData, setNfcData] = useState(null);
  const [isNFCAvailable, setIsNFCAvailable] = useState(false);
  const dispatch = useDispatch();

  
  // ===================
  const scan = async () => {
    if ("NDEFReader" in window) {
      setIsNFCAvailable(true);
      try {
        const ndef = new window.NDEFReader();
        await ndef.scan();

        alert("Scan started successfully.");
        ndef.onreadingerror = () => {
          alert("Cannot read data from the NFC tag. Try another one?");
        };

        ndef.onreading = (event) => {
          console.log("NDEF message read.");
          onReading(event); // Find function below
        };
      } catch (error) {
        console.log(`Error! Scan failed to start: ${error}.`);
      }
    } else {
      dispatch(openSnackbar("device does not supported NFC ","warning"))
      setIsNFCAvailable(false);
    }
  };

  const onReading = ({ message, serialNumber }) => {
    alert(serialNumber);

    console.log("working==>", message);
    setNfcData(serialNumber);
    console.log("typeof=>", typeof message);
  };

  const onWrite = async () => {
    try {
      const ndef = new window.NDEFReader();
      await ndef.write({
        records: [{ recordType: "text", data: "Hellow World!" }],
      });
      console.log(`Value Saved!`);
    } catch (error) {
      console.log(error);
    }
  };

  // ===================

  return (
    <>
      <Box>
        <Button onClick={() => scan()}>Scan Now</Button>
        <Button onClick={() => onWrite()}>write now</Button>

        {nfcData && isNFCAvailable ? <Typography>{nfcData}</Typography> : ""}
      </Box>
    </>
  );
};

export default NFCScan;
