import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Typography,
  Alert,
} from "@mui/material";

import React, { useEffect, useState } from "react";

import { useDispatch } from "react-redux";

import KeyIcon from "@mui/icons-material/Key";

import axios from "axios";

import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

import EventNoteIcon from "@mui/icons-material/EventNote";

import EmojiFoodBeverageIcon from "@mui/icons-material/EmojiFoodBeverage";

import StraightenIcon from "@mui/icons-material/Straighten";

import { useLocation, useNavigate, useParams } from "react-router-dom";

import { Helmet } from "react-helmet-async";

import { isLoading, openSnackbar } from "../redux/action/defaultActions";

import { baseUrl } from "../utils/apiUrl";

const CupInfoPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [scannedCupDetails, setscannedCupDetails] = useState(null);

  const { cupId } = useParams();


  // get scanned cup details
  const getScannedCupDetail = async () => {
    try {
      dispatch(isLoading(true));
      const res = await axios.put(`${baseUrl}/cup/store/cup/detail`, {
        // "uniqueID": "CUP046275e20a6480"
        uniqueID: `${cupId}`,
      });
      // console.log("res==>", res);
      if (res.status === 200 && res.data.cupData && res.data.message) {
        setscannedCupDetails(res.data.cupData);
      
        dispatch(openSnackbar(res.data.message, "success"));
      } else {
        dispatch(openSnackbar(res.data.message, "warning"));
      }
      dispatch(isLoading(false));
    } catch (error) {
      if (error.response.data.message) {
        dispatch(openSnackbar(error.response.data.message, "error"));
      } else if (error.data.message) {
        dispatch(openSnackbar(error.data.message, "error"));
      } else if (error.message) {
        dispatch(openSnackbar(error.message, "error"));
      } else {
        dispatch(openSnackbar("something went wrong", "error"));
      }
      dispatch(isLoading(false));
    }
  };

  // handleReturn cup
  const handleReturnCup = async (modelID, uniqueID) => {
  
    try {
      dispatch(isLoading(true));
      const res = await axios.put(`${baseUrl}/return/cup/detagging`, {
        modelID,
        uniqueID,
      });
      if (res.status === 200 && (res.data.message || res.data)) {
        dispatch(openSnackbar(res.data.message, "success"));
        navigate(-1);
      } else {
        dispatch(openSnackbar(res.data.message, "warning"));
      }
      dispatch(isLoading(false));
    } catch (error) {
      if (error.response.data.message) {
        dispatch(openSnackbar(error.response.data.message, "error"));
      } else if (error.data.message) {
        dispatch(openSnackbar(error.data.message, "error"));
      } else if (error.message) {
        dispatch(openSnackbar(error.message, "error"));
      } else {
        dispatch(openSnackbar("something went wrong", "error"));
      }
      dispatch(isLoading(false));
    }
  };

  useEffect(() => {
    if (cupId) {
      getScannedCupDetail();
    }
  }, []);

 

  return (
    <>
      <Helmet>
        <title> Dashboard : Recive | Cups | CHIPT </title>
      </Helmet>

      <Container>
        <Stack
          direction={"row"}
          spacing={1}
          sx={{ cursor: "pointer", "&>hover": { color: "red" } }}
          mb={1}
          onClick={() => navigate(-1)}
        >
          <KeyboardBackspaceIcon />{" "}
          <Typography variant="body2" component={"span"}>
            Back
          </Typography>
        </Stack>

        <Card
          sx={{
            minWidth: 275,
            // background: "rgba(253,227,236,0.5)",
            // background:
            //   "linear-gradient(34deg, rgba(253,227,236,1) 51%, rgba(203,241,254,1) 57%)",
          }}
        >
          <CardContent>
            {scannedCupDetails && scannedCupDetails !== null ? (
              <Grid
                container
                display={"flex"}
                alignItems={"center"}
                direction={"row"}
                justifyContent={"space-between"}
                spacing={2}
              >
                <Grid
                  item
                  xs={12}
                  md={4}
                  sm={6}
                  display={"flex"}
                  justifyContent={"center"}
                  flexDirection={"column"}
                  alignItems={"center"}
                  // minHeight={"250px"}
                >
                  <Typography variant="h3" textAlign={"center"}>
                    Cup Detail
                  </Typography>

                  <Box
                    component={"img"}
                    src={`${scannedCupDetails?.cupImages[0].url}`}
                    alt={`${scannedCupDetails?.cupImages[0].public_id}`}
                    // height={"100%"}
                    sx={{
                      width: "100%",
                      minWidth: "200px",
                      maxWidth: "250px",
                      maxHeight: "300px",
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={8} sm={6} p={2}>
                  <Box
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <List
                      sx={{
                        width: "100%",
                      }}
                    >
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar sx={{ bgcolor: "rgba(242,85,120,1)" }}>
                            <KeyIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary="Unique Cup Id"
                          sx={{ wordWrap: "break-word" }}
                          secondary={cupId}
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar sx={{ bgcolor: "rgba(80,150,254,1)" }}>
                            <EmojiFoodBeverageIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary="Cup Type"
                          secondary={scannedCupDetails?.cupType}
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar sx={{ bgcolor: "rgba(80,150,254,1)" }}>
                            <StraightenIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary="Cup Size"
                          secondary={scannedCupDetails?.cupSize}
                        />
                      </ListItem>

                      {scannedCupDetails?.orderDate &&
                      !scannedCupDetails?.isOrderable ? (
                        <ListItem>
                          <ListItemAvatar>
                            <Avatar sx={{ bgcolor: "rgba(242,85,120,1)" }}>
                              <EventNoteIcon />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            primary="Order Date Retrurn"
                            secondary={`${scannedCupDetails?.orderDate}`}
                          />
                        </ListItem>
                      ) : (
                        ""
                      )}
                    </List>
                  </Box>
                </Grid>
              </Grid>
            ) : (
              // navigate(-1)
              "No Cup Date Found"
            )}
          </CardContent>
        </Card>
        {!scannedCupDetails?.isOrderable ? (
          <Stack alignItems={"start"} mt={2}>
            <Button
              variant="contained"
              disabled={!scannedCupDetails && scannedCupDetails === null}
              onClick={() =>
                handleReturnCup(
                  scannedCupDetails.modelID,
                  scannedCupDetails.uniqueID
                )
              }
            >
              confirm return
            </Button>
          </Stack>
        ) : (
          <Stack alignItems={"start"} mt={2}>
            <Alert severity="warning">
              This cup is not ordered yet or can not be returned as its return
              date might expired !
            </Alert>
          </Stack>
        )}
      </Container>
    </>
  );
};

export default CupInfoPage;
