import { Navigate, useRoutes } from "react-router-dom";
import { useSelector } from "react-redux";
// layouts
import DashboardLayout from "./layouts/dashboard";
import SimpleLayout from "./layouts/simple";
//

import LoginPage from "./pages/LoginPage";
import Page404 from "./pages/Page404";

import DashboardAppPage from "./pages/DashboardAppPage";
import CupInfoPage from "./pages/CupInfoPage";
import FirstLoginMainPage from "./pages/FirstLoginMainPage";
import CoffeeInventoryMain from "./sections/@dashboard/coffee/CoffeeInventoryMain";
import RecieveCupsMain from "./sections/@dashboard/coffee/RecieveCupsMain";
import ReturnedCup from "./pages/ReturnedCup";
import Feedbacks from "./pages/Feedback";
import CupsPortfoilo from "./pages/CupsPortfoilo";
import AllUsers from "./pages/AllUsers";
import ProfilePage from "./pages/ProfilePage";
import PlaceOrderMain from "./sections/@dashboard/stock/PlaceOrderMain";
import OrdersMain from "./sections/@dashboard/orders/OrdersMain";
import ConfirmOrderScreen from "./sections/@dashboard/orders/ConfirmOrderScreen";
import NFCScan from "./sections/nfcscan/NFCScan";
import StoreLocation from "./sections/@dashboard/location/StoreLocation";
import FirstTimeLoginOtp from "./sections/auth/login/FirstTimeLoginOtp";
import UpdatePasswordFirstTimeLogin from "./sections/auth/login/UpdatePasswordFirstTimeLogin";

import VerifyEmailToGetResetOtp from "./components/forgetpassword/VerifyEmailToGetResetOtp";
import GenerateResetOtp from "./components/forgetpassword/GenerateResetOtp";
import UpdatePassword from "./components/forgetpassword/UpdatePassword";

// ----------------------------------------------------------------------

export default function Router() {
  const nfcTngVendor  = useSelector((state) => state.nfcTngVendor);
  const routes = useRoutes([
    nfcTngVendor?.isAuthenticated && nfcTngVendor?.isUser
      ? {
          path: "/",
          element: <DashboardLayout />,
          children: [
            nfcTngVendor?.isAuthenticated &&
            nfcTngVendor?.isUser &&
            nfcTngVendor?.firstTimeLogin
              ? { element: <Navigate to="/first/login" />, index: true }
              : nfcTngVendor?.isAuthenticated &&
                nfcTngVendor?.isUser &&
                !nfcTngVendor?.accountCreated
              ? { element: <Navigate to="/create-profile" />, index: true }
              : { element: <Navigate to="/app" />, index: true },

            { path: "/app", element: <DashboardAppPage /> },

            // { path: "/update-profile", element: <FirstLoginMainPage /> },
            { path: "/profile", element: <ProfilePage /> },
            { path: "/recieve/cup/info/:cupId", element: <CupInfoPage /> },
            { path: "/returned", element: <ReturnedCup /> },
            { path: "/inventory", element: <CoffeeInventoryMain /> },
            { path: "/place/order", element: <PlaceOrderMain /> },
            // { path: "/coffee", element: <ProductsPage /> },
            { path: "/cups", element: <CupsPortfoilo /> },
            { path: "/orders", element: <OrdersMain /> },
            {
              path: "/orders/confirm/order/:orderId",
              element: <ConfirmOrderScreen />,
            },
            { path: "/all/users", element: <AllUsers /> },

            { path: "/recieve", element: <RecieveCupsMain /> },
            { path: "/feedback", element: <Feedbacks /> },
            { path: "/location", element: <StoreLocation /> },
          ],
        }
      : {
          path: "/",
          element: <SimpleLayout />,
          children: [
            { element: <Navigate to="/login" />, index: true },
            { path: "/404", element: <Page404 /> },
            { path: "404", element: <Page404 /> },
            { path: "*", element: <Navigate to="/login" /> },
          ],
        },

    { path: "/first/login", element: <FirstTimeLoginOtp /> },

    { path: "/create-profile", element: <FirstLoginMainPage /> },

    {
      path: "/first/password-update",
      element: <UpdatePasswordFirstTimeLogin />,
    },
    {
      path: "/forget-password",
      element: <VerifyEmailToGetResetOtp />,
    },
    {
      path: "/forget-password/otp-verification",
      element: <GenerateResetOtp />,
    },
    {
      path: "/forget-password/update-password",
      element: <UpdatePassword />,
    },
    {
      path: "/login",
      element: <LoginPage />,
    },
    {
      path: "/scan",
      element: <NFCScan />,
    },
    {
      path: "*",
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
