import { ActionTypes } from "../constants/actionTypes";

export const login = (data) => {
  return {
    type: ActionTypes.ADMIN_LOGIN,

    payload: data,
  };
};

export const firtTimeLogin = (data) => {
  return {
    type: ActionTypes.FIRST_TIME_LOGIN,
    payload: data,
  };
};

export const logout = () => {
  return {
    type: ActionTypes.ADMIN_LOGOUT,
    payload: {},
  };
};

// admin auth action
export const adminAuth = (data) => {
  return {
    type: ActionTypes.ADMIN_AUTH,
    payload: data,
  };
};

export const updateProfileData = (data) => {
  return {
    type: ActionTypes.VENDOR_PROFILE_DATA,
    payload: data,
  };
};
