import {
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import React, { useEffect, useState } from "react";

import { Helmet } from "react-helmet-async";

import AddIcon from "@mui/icons-material/Add";

import RemoveIcon from "@mui/icons-material/Remove";

import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";

import axios from "axios";
import CloseIcon from "@mui/icons-material/Close";

import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

import { isLoading, openSnackbar } from "../../../redux/action/defaultActions";
import { baseUrl } from "../../../utils/apiUrl";

const PlaceOrderMain = () => {
  const nfcTngVendor  = useSelector((state) => state.nfcTngVendor);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [cupTypeModels, setCupTypeModels] = useState([]);

  const [orderCoffeeArray, setOrderCoffeeArray] = useState([
    { numberOfCups: 0 },
  ]);

  const [shippingAddressValues, setShippingAddressValues] = useState({});
  const [cupSize, setCupSize] = useState([[""]]);
  // add new option
  const handleAdd = () => {
    const temp = [...orderCoffeeArray];
    temp.push({});
    setOrderCoffeeArray([...temp]);
  };
  // remove options
  const handleRemove = (index) => {
    const temp = orderCoffeeArray;

    temp.splice(index, 1);

    setOrderCoffeeArray([...temp]);
  };

  // update values of the options
  const handleChange = (e, index) => {
    let sizes;

    // check if the value changes for category
    if (e.target.name === "cupType") {
      cupTypeModels?.forEach((model) => {
        if (model.cupType === e.target.value) {
         
          sizes = model.availableSize;
        }
        return "";
      });

      // get the size of eact category on every value change
      sizes = sizes?.map((size) => size);
      const m = cupSize;
      m.splice(index, 1, sizes);
     
      setCupSize(m);
    }
    const temp = orderCoffeeArray;
    temp[index] = { ...temp[index], [e.target.name]: e.target.value };

    setOrderCoffeeArray([...temp]);
  };
 

  // update shipping address values
  const handleChangeShippingAddressValues = (e) =>
    setShippingAddressValues({
      ...shippingAddressValues,
      [e.target.name]: e.target.value,
    });

  // submit order details
  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(isLoading(true));
    try {
      const res = await axios.post(`${baseUrl}/store/new/stock/request`, {
        cupsRequested: [...orderCoffeeArray],
      });
      if (res.status === 200 && res.data.message) {
        dispatch(openSnackbar(res.data.message, "success"));
        navigate("/orders");
      } else {
        dispatch(openSnackbar(res.data.message, "warning"));
      }

      dispatch(isLoading(false));
      setOrderCoffeeArray([{ numberOfCups: 0 }]);
    } catch (error) {
      if (error.response.data.message) {
        dispatch(openSnackbar(error.response.data.message, "error"));
      } else if (error.data.message) {
        dispatch(openSnackbar(error.data.message, "error"));
      } else if (error.message) {
        dispatch(openSnackbar(error.message, "error"));
      } else {
        dispatch(openSnackbar("something went wrong", "error"));
      }
      dispatch(isLoading(false));
    }
  };

  // get cuptype and cupmodels
  const getCupsModelCupType = async () => {
    dispatch(isLoading(true));
    try {
      const res = await axios.get(`${baseUrl}/cup/all/available/inventory`);
      setCupTypeModels(res?.data?.cups);
     
      dispatch(isLoading(false));
    } catch (error) {
      if (error.response.data.message) {
        dispatch(openSnackbar(error.response.data.message, "error"));
      } else if (error.data.message) {
        dispatch(openSnackbar(error.data.message, "error"));
      } else if (error.message) {
        dispatch(openSnackbar(error.message, "error"));
      } else {
        dispatch(openSnackbar("something went wrong", "error"));
      }
      dispatch(isLoading(false));
    }
  };

  useEffect(() => {
    setShippingAddressValues({
      plotNumber: nfcTngVendor?.plotnumber,
      address: nfcTngVendor?.address,
      city: nfcTngVendor?.city,
      state: nfcTngVendor?.state,
      country: nfcTngVendor?.country,
      zipCode: nfcTngVendor?.zipCode,
    });
    getCupsModelCupType();
  }, []);

  return (
    <>
      <Helmet>
        <title> Dashboard: Place Order | NFC | CHIPT </title>
      </Helmet>

      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={2}
        >
          <Typography variant="h4" gutterBottom>
            Place Orders
          </Typography>
          <Stack
            direction={"row"}
            spacing={1}
            sx={{ cursor: "pointer", "&>hover": { color: "red" } }}
            mb={1}
            onClick={() => navigate(-1)}
          >
            <KeyboardBackspaceIcon />{" "}
            <Typography variant="body2" component={"span"}>
              Back
            </Typography>
          </Stack>
        </Stack>
        <Stack component={"form"} onSubmit={handleSubmit}>
          <Card sx={{ minWidth: 275, transition: "ease-in 300ms" }}>
            <CardContent>
              <Stack>
                <Typography variant="h6" color={"gray"}>
                  Delivery Address
                </Typography>
              </Stack>
              <Grid
                mt={1}
                container
                py={1}
                px={1}
                alignItems={"center"}
                sx={{
                  border: "1px solid lightgrey",
                }}
                spacing={1}
              >
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    fullWidth
                    required
                    name="plotNumber"
                    size="small"
                    type="number"
                    value={
                      shippingAddressValues?.plotNumber
                        ? shippingAddressValues?.plotNumber
                        : ""
                    }
                    label="Building/Plot No."
                    onChange={handleChangeShippingAddressValues}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    fullWidth
                    required
                    name="address"
                    value={
                      shippingAddressValues?.address
                        ? shippingAddressValues?.address
                        : ""
                    }
                    size="small"
                    label="Address"
                    onChange={handleChangeShippingAddressValues}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    fullWidth
                    required
                    name="city"
                    value={
                      shippingAddressValues?.city
                        ? shippingAddressValues?.city
                        : ""
                    }
                    size="small"
                    label="City"
                    onChange={handleChangeShippingAddressValues}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    fullWidth
                    required
                    name="state"
                    size="small"
                    value={
                      shippingAddressValues?.state
                        ? shippingAddressValues?.state
                        : ""
                    }
                    label="State"
                    onChange={handleChangeShippingAddressValues}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    fullWidth
                    required
                    name="country"
                    value={
                      shippingAddressValues?.country
                        ? shippingAddressValues?.country
                        : ""
                    }
                    size="small"
                    label="Country"
                    onChange={handleChangeShippingAddressValues}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    fullWidth
                    required
                    name="zipCode"
                    type="number"
                    value={
                      shippingAddressValues?.zipCode
                        ? shippingAddressValues?.zipCode
                        : null
                    }
                    size="small"
                    label="zipCode"
                    onChange={handleChangeShippingAddressValues}
                  />
                </Grid>
              </Grid>
              <Stack mt={2}>
                <Typography variant="h6" color={"gray"}>
                  Items
                </Typography>
              </Stack>
              {orderCoffeeArray?.map((row, index) => {
                return (
                  <Grid
                    mt={2}
                    container
                    key={index}
                    py={1}
                    px={1}
                    alignItems={"center"}
                    position={"relative"}
                    sx={{
                      border: "1px solid lightgrey",
                      // borderRadius: "10px",
                      // boxShadow: "7px 19px 20px -20px #161515bf;",
                    }}
                    spacing={1}
                  >
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        name="cupType"
                        required
                        value={row?.cupType ? row?.cupType : ""}
                        sx={{ textTransform: "capitalize" }}
                        onChange={(e) => handleChange(e, index)}
                        size="small"
                        label="choose cuptype"
                        // defaultValue={"coffee"}
                        select
                        fullWidth
                        multiline
                      >
                        <MenuItem value={""} disabled>
                          --select--
                        </MenuItem>
                        {cupTypeModels?.map((type, rowIndex) => {
                          return (
                            <MenuItem
                              key={rowIndex}
                              value={type?.cupType}
                              sx={{ textTransform: "capitalize" }}
                            >
                              {type?.cupType}
                            </MenuItem>
                          );
                        })}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        name="cupModelUniqueId"
                        fullWidth
                        required
                        value={
                          row?.cupModelUniqueId ? row?.cupModelUniqueId : ""
                        }
                        // defaultValue={"no"}
                        onChange={(e) => handleChange(e, index)}
                        sx={{ textTransform: "capitalize" }}
                        size="small"
                        label={
                          cupSize[index]?.length > 0
                            ? "choose cupsize"
                            : "first choose a cuptype"
                        }
                        // defaultValue={"regular"}
                        disabled={!cupSize[index]?.length}
                        select
                        multiline
                      >
                        <MenuItem value="" disabled>
                          --select--
                        </MenuItem>
                        {cupSize[index]?.map((v, i) => {
                          return (
                            <MenuItem key={i} value={v?.cupModelUniqueId}>
                              {v?.cupSize}
                            </MenuItem>
                          );
                        })}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Stack direction={"row"} spacing={1}>
                        <TextField
                          required
                          name="numberOfCups"
                          fullWidth
                          type="number"
                          value={row?.numberOfCups ? row?.numberOfCups:null}
                          label="number of cups"
                          onChange={(e) => handleChange(e, index)}
                          size="small"
                          defaultValue={0}
                        />
                      </Stack>
                    </Grid>
                    <IconButton
                      onClick={() => handleRemove(index)}
                      sx={{
                        position: "absolute",
                        top: -15,
                        right: -17,
                        color: "gray",
                        display:
                          orderCoffeeArray?.length !== 1 ? "block" : "none",
                      }}
                    >
                      <CloseIcon sx={{ fontSize: "18px" }} />
                    </IconButton>
                  </Grid>
                );
              })}
              <Stack
                mt={1}
                alignItems={"center"}
                justifyContent={"end"}
                direction={"row"}
              >
                <Button
                  onClick={handleAdd}
                  variant="outlined"
                  startIcon={<AddIcon />}
                >
                  Add Items
                </Button>
              </Stack>
            </CardContent>
          </Card>
          <Stack
            mt={2}
            // spacing={1}
            alignItems={"center"}
            justifyContent={"start"}
            direction={"row"}
          >
            <Button type="submit" variant="contained">
              Place Order
            </Button>
          </Stack>
        </Stack>
      </Container>
    </>
  );
};

export default PlaceOrderMain;
