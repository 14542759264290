import { ActionTypes } from "../constants/actionTypes";

export const openSnackbar = (message, severity) => {
  return {
    type: ActionTypes.OPEN_SNACKBAR,
    payload: { message, severity },
  };
};

export const closeSnackbar = () => {
  return {
    type: ActionTypes.CLOSE_SNACKBAR,
    payload: {},
  };
};

export const isLoading = (open) => {
  return {
    type: ActionTypes.SET_ISLOADING,
    payload: open,
  };
};

export const toggleGenerateOTPModalOpen = () => {
  return {
    type: ActionTypes.MODAL_TOGGLER_OTP_TOGGLER,
    payload: {},
  };
};


export const togglePasswordGenerateModalOpen = () => {
  return {
    type: ActionTypes.MODAL_TOGGLER_PASSWORD_CREATION,
    payload: {},
  };
};