import { Helmet } from "react-helmet-async";
import { faker } from "@faker-js/faker";
import { useNavigate } from "react-router-dom";
// @mui
import { useTheme } from "@mui/material/styles";
import { useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import axios from "axios";
import { Grid, Container, Typography, Stack, Button } from "@mui/material";
// components
// sections
import { AppWidgetSummary } from "../sections/@dashboard/app";

import { isLoading, openSnackbar } from "../redux/action/defaultActions";
import { baseUrl } from "../utils/apiUrl";

// ----------------------------------------------------------------------

export default function DashboardAppPage() {
  const theme = useTheme();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [dashboardCardData, setDashboardCardData] = useState(null);

  const getDashboardCardData = async () => {
    dispatch(isLoading(true));
    try {
      const res = await axios.get(`${baseUrl}/store/dashboard/all/details`);
    
      setDashboardCardData(res?.data?.vendorData);
      dispatch(isLoading(false));
    } catch (error) {
      console.log("error=>", error);
      if (error.response.data.message) {
        dispatch(openSnackbar(error.response.data.message, "error"));
      } else {
        dispatch(openSnackbar("something went wrong", "error"));
      }
      dispatch(isLoading(false));
    }
  };

  useEffect(() => {
    getDashboardCardData();
  }, []);

  return (
    <>
      <Helmet>
        <title> Dashboard | Vendor App </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Hi, Welcome back
        </Typography>
        {/* {dashboardCardData ? ( */}
        <Grid container spacing={3}>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            sx={{
              transition: "ease-in all 300ms",
              cursor: "pointer",
              "&:hover": { scale: "1.075" },
            }}
            // onClick={() => {
            //   navigate("/cups");
            // }}
          >
            <AppWidgetSummary
              title={"Total Cups"}
              total={dashboardCardData?.cups ? dashboardCardData?.cups : 0}
              icon={"game-icons:coffee-mug"}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            sx={{
              transition: "ease-in all 300ms",
              cursor: "pointer",
              "&:hover": { scale: "1.075" },
            }}
            onClick={() => {
              navigate("/all/users");
            }}
          >
            <AppWidgetSummary
              title="All Users"
              total={
                dashboardCardData?.customers ? dashboardCardData?.customers : 0
              }
              color="info"
              icon={"raphael:users"}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            sx={{
              transition: "ease-in all 300ms",
              cursor: "pointer",
              "&:hover": { scale: "1.075" },
            }}
            // onClick={() => {
            //   navigate("/cups");
            // }}
          >
            <AppWidgetSummary
              title="Coffee Orders"
              total={dashboardCardData?.orders ? dashboardCardData?.orders : 0}
              color="warning"
              icon={"emojione-monotone:beer-mug"}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            sx={{
              transition: "ease-in all 300ms",
              cursor: "pointer",
              "&:hover": { scale: "1.075" },
            }}
            // onClick={() => {
            //   navigate("/cups");
            // }}
          >
            <AppWidgetSummary
              title="Transanctions"
              total={
                dashboardCardData?.cupRequests
                  ? dashboardCardData?.cupRequests
                  : 0
              }
              color="error"
              icon={"tdesign:undertake-transaction"}
            />
          </Grid>
        </Grid>
        {/* ) : (
          <Typography variant="body2" color={"error"} component={"p"}>
            There are no transactions found for your store! Start Transactions ,
            made customers to visit your store
          </Typography>
        )
        } */}
        <Stack mt={2}>
          <Typography variant="h6">Quick Links</Typography>
        </Stack>
        <Stack mt={1} direction={"row"} spacing={1}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate("/recieve")}
          >
            Recieve Cup
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate("/place/order")}
          >
            Place Order
          </Button>
        </Stack>
      </Container>
    </>
  );
}
