import { combineReducers, createStore } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import {
  
  generateOTPModalTogglerReducer,
  generatePasswordModalTogglerReducer,
  setIsLoading,
  snacbarReducer,
} from "./defaultReducer";
import { vendorReducer } from "./vendorReducer";

const allAdminReducer = combineReducers({
  nfcTngVendor: vendorReducer,
  snackbar: snacbarReducer,
  loading: setIsLoading,
  generatePasswordOTPModalOpen: generateOTPModalTogglerReducer,
  generatePasswordUpdateModalOpen: generatePasswordModalTogglerReducer,

});

const reducer = allAdminReducer;
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["nfcTngVendor"],
};

const adminPersistReducer = persistReducer(persistConfig, reducer);

export const store = createStore(
  adminPersistReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  // process.env.NODE_ENV !== 'production'
);
export const persistor = persistStore(store);
// export default allAdminReducer;
