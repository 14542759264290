import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { openSnackbar } from "../../../redux/action/defaultActions";

import QrCodeScanner from "./QrCodeScanner";

const scrollToTop = () => {
  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
};

const RecieveCupsMain = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [nfcData, setNfcData] = useState(null);

  const [isNFCAvailable, setIsNFCAvailable] = useState(false);

  const [showDetails, setshowDetails] = useState(true);

  const [cupChiptUIN, setcupChiptUIN] = useState({});

  const [isScanned, setIsScanned] = useState(false);

  const handleChange = (e) =>
    setcupChiptUIN({ ...cupChiptUIN, [e.target.name]: e.target.value });

  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      setshowDetails(true);
      navigate(`/recieve/cup/info/${cupChiptUIN?.chipt_uin}`);
    
    } catch (error) {
      console.log({ error });
    }
  };

  //
  const scan = async () => {
    if ("NDEFReader" in window) {
      setIsNFCAvailable(true);
      try {
        const ndef = new window.NDEFReader();
        await ndef.scan();

        dispatch(openSnackbar("Scan started successfully.", "success"));
        ndef.onreadingerror = () => {
          dispatch(
            openSnackbar(
              "Cannot read data from the NFC tag. Try another one?",
              "warning"
            )
          );
        };

        ndef.onreading = (event) => {
          console.log("NDEF message read.");
          onReading(event); // Find function below
        };
      } catch (error) {
        dispatch(
          openSnackbar(`Error! Scan failed to start: ${error}.`, "warning")
        );
      }
    } else {
      dispatch(openSnackbar("device does not supported NFC ", "warning"));
      setIsNFCAvailable(false);
    }
  };

  //
  const onReading = ({ message, serialNumber }) => {
    dispatch(
      openSnackbar(`Scanned Cup Serial No : ${serialNumber}`, "success")
    );

    console.log("working==>", message);
    setNfcData(serialNumber);
    // CHIPTLIYKEAZJB6
    if (serialNumber) {
      navigate(`/recieve/cup/info/${serialNumber}`);
    }
  };


  const redirectToCupInfoPage = (serialNumber) =>{
    navigate(`/recieve/cup/info/${serialNumber}`);
  }

  
  const onWrite = async () => {
    try {
      const ndef = new window.NDEFReader();
      await ndef.write({
        records: [{ recordType: "text", data: "Hello World!" }],
      });
      console.log(`Value Saved!`);
    } catch (error) {
      console.log(error);
    }
  };

  // ===================
  return (
    <>
      <Helmet>
        <title> Dashboard : Recive | Cups | CHIPT </title>
      </Helmet>

      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Recive Returned Cup
        </Typography>
        <Grid container>
          
          <Grid item xs={12} sm={5} alignItems={"center"}>
            <Typography variant="h4" sx={{ mb: 5 }}>
              Tap NFC
            </Typography>
            <Box
              width={"100%"}
              maxWidth={"350px"}
              sx={{ cursor: "pointer" }}
              onClick={() => scan()}
            >
              <img width={"100%"} src="./assets/icons/nfc.gif" alt="SCANNER" />
            </Box>
            {nfcData && isNFCAvailable ? (
              <Typography mt={2}>{nfcData}</Typography>
            ) : (
              ""
            )}
          </Grid>
          <Grid item xs={12} sm={1} display={"flex"} alignItems={"center"}>
            <Typography variant="h5" fontStyle={"bold"}>
              {" "}
              OR
            </Typography>
          </Grid>
          <Grid item xs={12} sm={5}>
            <QrCodeScanner
              isScanned={isScanned}
              setIsScanned={setIsScanned}
              cupDetail={nfcData}
              setCupDetail={setNfcData}
              scrollToTop={scrollToTop}
              doAction={redirectToCupInfoPage}
            />
          </Grid>

          
          <Grid item xs={12}>
            <Typography variant="h4" color={"primary"} sx={{ mb: 5 }}>
              Enter Cup UIN Number
            </Typography>
            <form onSubmit={handleSubmit}>
              <Stack spacing={1} alignItems={"start"}>
                <TextField
                  size="small"
                  name="chipt_uin"
                  label="Chipt UIN number"
                  placeholder="Enter CHIPT UIN number"
                  onChange={handleChange}
                  required
                />
                <Button type="submit" variant="outlined" color="primary">
                  Proceed
                </Button>
              </Stack>
            </form>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default RecieveCupsMain;
