import { ActionTypes } from "../constants/actionTypes";

const initialState = {
  isAuthenticated: false,
  isUser: false,
  firstTimeLogin: false,
  accountCreated: false,
};
export const vendorReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.ADMIN_LOGIN:
      return { ...state, ...payload, isAuthenticated: true, isUser: true };
    case ActionTypes.FIRST_TIME_LOGIN:
      return {
        ...state,
        ...payload,
        isAuthenticated: true,
        isUser: true,
      };
    case ActionTypes.VENDOR_PROFILE_DATA: {
      return { ...state, ...payload };
    }
    case ActionTypes.ADMIN_AUTH:
      return { ...state, ...payload, isAuthenticated: true, isUser: true };
    case ActionTypes.ADMIN_LOGOUT:
      return { ...initialState };
    default:
      return state;
  }
};
