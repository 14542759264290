import React, { useState } from "react";

import {
  Backdrop,
  Box,
  Button,
  Fade,
  IconButton,
  InputAdornment,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch } from "react-redux";
import Iconify from "../../../components/iconify";
import { togglePasswordGenerateModalOpen } from "../../../redux/action/defaultActions";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid rgba(32,101,209,1)",
  boxShadow: 24,
  p: 4,
};
const GeneratePassword = ({
  open,
  handleClose,
  handleUpdatePassword,
  passwordValues,
  setPasswordValues,
}) => {
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);

  const [passwordCompared, setPasswordCompared] = useState({
    checked: 0,
    message: "",
  });
  //   state to check compared password

  const handleChange = (e) =>
    setPasswordValues({ ...passwordValues, [e.target.name]: e.target.value });
  // === handle compare password ===
  const handleComparePassword = () => {
    if (
      passwordValues?.password.length > 0 &&
      passwordValues?.password === passwordValues?.confirmPassword
    ) {
      setPasswordCompared({
        checked: 1,
        message: "Confirm password mathced !",
      });
    } else if (passwordValues?.password.length <= 0) {
      setPasswordCompared({
        checked: -1,
        message: "Please Enter A Password",
      });
    } else {
      setPasswordCompared({
        checked: -1,
        message: "Confirm password doesn't match !",
      });
    }
  };
  // ================================
  const handleSubmit = async (e) => {
    e.preventDefault();
    await handleUpdatePassword();
    dispatch(togglePasswordGenerateModalOpen());
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      // onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <Stack direction={"row"}>
            <Typography
              color={"primary"}
              sx={{ textTransform: "uppercase" }}
              variant="h6"
              component="h2"
            >
              Create Password
            </Typography>
          </Stack>
          <Stack component={"form"} onSubmit={handleSubmit} mt={1} spacing={2}>
            <TextField
              type="password"
              name="oneTimePassword"
              value={
                passwordValues?.oneTimePassword
                  ? passwordValues?.oneTimePassword
                  : ""
              }
              label="One Time Password"
              required
              size="small"
              onChange={handleChange}
            />
            <TextField
              type="password"
              name="password"
              value={passwordValues?.password ? passwordValues?.password : ""}
              label="New Password"
              required
              size="small"
              onChange={handleChange}
            />
            <TextField
              size="small"
              name="confirmPassword"
              value={
                passwordValues?.confirmPassword
                  ? passwordValues?.confirmPassword
                  : ""
              }
              helperText={`*${passwordCompared?.message}`}
              sx={{
                "&>.MuiFormHelperText-root": {
                  color:
                    passwordCompared?.checked === 1
                      ? "green"
                      : passwordCompared?.checked === -1
                      ? "red"
                      : "grey",
                },
              }}
              required
              label="Confirm password"
              type={showPassword ? "text" : "password"}
              // solar:eye-bold-duotone

              InputProps={{
                endAdornment: (
                  <InputAdornment position="start" sx={{ fontSize: "20px" }}>
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      <Iconify
                        icon={
                          showPassword ? "eva:eye-fill" : "eva:eye-off-fill"
                        }
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onChange={handleChange}
              onBlur={handleComparePassword}
            />

            <Stack>
              <Button type="submit" variant="contained" color="primary">
                Generate Password
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Fade>
    </Modal>
  );
};

export default GeneratePassword;
