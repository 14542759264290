import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import { Helmet } from "react-helmet-async";

import { useNavigate } from "react-router-dom";

import { MuiTelInput } from "mui-tel-input";

import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

import { useDispatch, useSelector } from "react-redux";

import { replace } from "lodash";

import axios from "axios";

import OtpModal from "../sections/auth/generatepassword/OtpModal";

import GeneratePassword from "../sections/auth/generatepassword/GeneratePassword";

import {
  isLoading,
  openSnackbar,
  toggleGenerateOTPModalOpen,
  togglePasswordGenerateModalOpen,
} from "../redux/action/defaultActions";

import Iconify from "../components/iconify";
import { baseUrl } from "../utils/apiUrl";
import {
  adminAuth,
  firtTimeLogin,
  logout,
  updateProfileData,
} from "../redux/action/adminActions";
import { googleMapApiKey } from "../utils/keys";

const FirstLoginMainPage = () => {
  const {
    nfcTngVendor,
    generatePasswordOTPModalOpen,
    generatePasswordUpdateModalOpen,
  } = useSelector((state) => state);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [otp, setOtp] = React.useState("");

  const [passwordValues, setPasswordValues] = useState({});

  const [selectedLocation, setSelectedLocation] = useState(null);

  const [confirmLolcation, setConfirmLolcation] = useState(false);

  const [map, setmap] = useState(null);

  const [vendorValues, setVendorValues] = useState({});

  const [phone, setPhone] = React.useState("");
  const [secondaryContact, setsecondaryContact] = React.useState("");

  const handleChange = (newPhone) => {
    setPhone(newPhone);
  };

  const handleChangeVendorValues = (e) => {
    if (e.target.name === "username") {
      setVendorValues({
        ...vendorValues,
        [e.target.name]: e.target.value.replace(/\s/g, ""),
      });
    } else {
      setVendorValues({ ...vendorValues, [e.target.name]: e.target.value });
    }
  };

  // check whether space key is pressed or not
  const handleSpace = (e) => {
    if (e.keyCode === 32) {
      dispatch(
        openSnackbar(
          "Username can't contain space. Space is not allowed",
          "warning"
        )
      );
    }
  };

  // Initial position for the map
  const center = {
    lat: 51.5074,
    lng: -0.1278,
  };
  //   handle marker click to set co-ordinates===
  const handleMarkerClick = (event) => {
    setSelectedLocation({
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    });
    setConfirmLolcation(false);
  };
  //   ================================
  const handleSubmitVendorForm = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.put(`${baseUrl}/account/information/update`, {
        ...vendorValues,
        contact: Number(phone.slice(-11).replace(/\s/g, "")),
        secondaryContact: Number(
          secondaryContact.slice(-10).replace(/\s/g, "")
        ),
        countryCode: Number(
          phone.slice(-(phone.length - 1), -10).replace(/\s/g, "")
        ),
        location: [selectedLocation.lat, selectedLocation.lng],
      });
      // console.log("working");

      dispatch(adminAuth({ ...nfcTngVendor, accountCreated: true }));
      if ((res.status === 200 || res.status === 201) && res.data.message) {
        dispatch(openSnackbar(res.data.message, "success"));
      } else {
        dispatch(openSnackbar(res.data.message, "success"));
      }
      navigate("/app");
      // dispatch(updateProfileData());
    } catch (error) {
      if (error?.response?.data?.message) {
        dispatch(openSnackbar(error?.response?.data?.message, "error"));
      } else if (error?.data?.message) {
        dispatch(openSnackbar(error?.data?.message, "error"));
      } else if (error?.message) {
        dispatch(openSnackbar(error?.message, "error"));
      } else {
        dispatch(openSnackbar("something went wrong", "error"));
      }
      dispatch(isLoading(false));
    }
  };

  // ================================
  const handleToggler = () => {
    dispatch(toggleGenerateOTPModalOpen());
  };

  const handleTogglePasswordUpdateModal = () => {
    dispatch(togglePasswordGenerateModalOpen());
  };
  // ================================
  const handleConfirmOtp = async () => {
    dispatch(toggleGenerateOTPModalOpen());
    dispatch(isLoading(true));
    console.log({ otp });
    try {
      const res = await axios.put(`${baseUrl}/account/store/verification`, {
        otp: Number(otp),
      });
      if (res.status === 200) {
        dispatch(togglePasswordGenerateModalOpen());
        dispatch(firtTimeLogin({ firstTimeLogin: false }));
        dispatch(isLoading(false));
      } else {
        dispatch(isLoading(false));
        dispatch(openSnackbar("invalid otp", "error"));
        // dispatch(toggleGenerateOTPModalOpen());
      }
    } catch (error) {
      console.log({ error });
      if (error?.response?.data?.message) {
        dispatch(openSnackbar(error?.response?.data?.message, "error"));
      }
      dispatch(openSnackbar("somethin went wrong", "error"));
      dispatch(logout());
      dispatch(isLoading(false));
      dispatch(toggleGenerateOTPModalOpen());
      navigate("/login");
    }
    // =========================
    // =========================
  };
  // ================================
  const handleResendOtp = async () => {
    dispatch(isLoading(true));

    try {
      const res = await axios.get(`${baseUrl}/authentication/resend/otp`);
      console.log("res==>", res);
      dispatch(isLoading(false));
      dispatch(toggleGenerateOTPModalOpen());
    } catch (error) {
      if (error.response.data.message) {
        dispatch(openSnackbar(error.response.data.message, "error"));
      } else {
        dispatch(openSnackbar("something went wrong", "error"));
      }
      dispatch(isLoading(false));
    }
  };
  // ============================
  const handleUpdatePassword = async () => {
    console.log("passoword==>", passwordValues);
    dispatch(isLoading(true));
    dispatch(togglePasswordGenerateModalOpen());
    try {
      const res = await axios.put(`${baseUrl}/authentication/password/change`, {
        oldpassword: passwordValues?.oneTimePassword,
        newpassword: passwordValues?.password,
        confirmpassword: passwordValues?.confirmPassword,
      });
      console.log("====>", res);

      dispatch(isLoading(false));
    } catch (error) {
      alert("error");
      if (error.response.data.message) {
        dispatch(openSnackbar(error.response.data.message, "error"));
      } else {
        dispatch(openSnackbar("something went wrong", "error"));
      }

      dispatch(togglePasswordGenerateModalOpen());
      dispatch(isLoading(false));
      console.log({ error });
    }
  };

  // get profile data
  const getProfileData = async () => {
    try {
      const res = await axios.get(`${baseUrl}/account/information`);

      // dispatch(updateProfileData(res?.data?.user));
      setVendorValues({
        name: res.data.user.name,
        email: res.data.user.email,
        address: res.data.user.address,
        city: res.data.user.city,
        country: res.data.user.country,
        zipCode: res.data.user.zipCode,
        state: res.data.user.state,
        plotnumber: res.data.user.plotnumber,

        secondaryEmail: res.data.user.secondaryEmail,
      });
      setPhone(`+${res.data.user.countryCode} ${res.data.user.contactNumber}`);
      setsecondaryContact(
        `+${res.data.user.countryCode} ${res.data.user.secondaryContact}`
      );
      setSelectedLocation({
        lat: res.data.user.location.coordinates[0]
          ? res.data.user.location.coordinates[0]
          : center.lat,
        lng: res.data.user.location.coordinates[1]
          ? res.data.user.location.coordinates[1]
          : center.lng,
      });
    } catch (error) {
      if (error.response.data.message) {
        dispatch(openSnackbar(error.response.data.message, "error"));
      } else if (error.data.message) {
        dispatch(openSnackbar(error.data.message, "error"));
      } else {
        dispatch(openSnackbar("something went wrong", "error"));
      }
    }
  };

  // ============================
  useEffect(() => {
    if (nfcTngVendor?.email && nfcTngVendor?.firstTimeLogin) {
      setVendorValues({
        ...vendorValues,
        email: nfcTngVendor?.email,
      });

      // dispatch(toggleGenerateOTPModalOpen());
    } else if (nfcTngVendor?.email && !nfcTngVendor?.firstTimeLogin) {
      // navigate("/", { replace: true });
      setVendorValues({ ...vendorValues, email: nfcTngVendor?.email });
    }
    getProfileData();
  }, []);

  return (
    <>
      <Helmet>
        <title> Dashboard | First Time Login </title>
      </Helmet>

      <Container maxWidth="xl">
        <Stack mt={1} alignItems={"center"} justifyContent={"center"}>
          <img src="./assets/chipt-logo.png" width={"120px"} alt="logo" />
          <Typography variant="h3">Create Account</Typography>
          <Typography variant="h5">Hi, {nfcTngVendor?.email}</Typography>
          <Typography variant="body2" component={"p"}>
            You'r Welcome , Please Setup Your accound First ...
          </Typography>
        </Stack>

        <Card>
          <Box component={"form"} onSubmit={handleSubmitVendorForm}>
            <CardContent>
              <Typography variant="h4" sx={{ color: "grey" }} mb={1}>
                Update Prfofile <Iconify icon="mingcute:information-fill" />{" "}
              </Typography>
              <Grid
                container
                display={"flex"}
                justifyContent={"space-between"}
                rowGap={2.5}
                columnGap={3}
                mb={2}
              >
                <Grid item xs={12} md={5.5}>
                  <TextField
                    name="username"
                    fullWidth
                    required
                    label="Username"
                    size="small"
                    color="warning"
                    placeholder="Create username"
                    value={vendorValues?.username ? vendorValues?.username : ""}
                    onChange={handleChangeVendorValues}
                    onKeyDown={handleSpace}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <Iconify icon="iconamoon:profile-light" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={5.5}>
                  <TextField
                    name="name"
                    fullWidth
                    label="Name"
                    size="small"
                    required
                    placeholder="Enter your name"
                    value={vendorValues?.name ? vendorValues?.name : ""}
                    onChange={handleChangeVendorValues}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <Iconify icon="iconamoon:profile-light" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={5.5}>
                  <TextField
                    fullWidth
                    name="email"
                    label="Email"
                    size="small"
                    disabled
                    value={vendorValues?.email ? vendorValues?.email : ""}
                    onChange={handleChangeVendorValues}
                    placeholder="Enter Email of Vendor"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <Iconify icon="clarity:email-line" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={5.5}>
                  <TextField
                    fullWidth
                    name="secondaryEmail"
                    label="Secodary Email"
                    size="small"
                    // disabled
                    // required
                    value={
                      vendorValues?.secondaryEmail
                        ? vendorValues?.secondaryEmail
                        : ""
                    }
                    onChange={handleChangeVendorValues}
                    placeholder="Enter Email of Vendor"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <Iconify icon="clarity:email-line" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={5.5}>
                  <MuiTelInput
                    fullWidth
                    label="Mobile Number"
                    defaultCountry="MY"
                    size="small"
                    required
                    disabled
                    value={phone}
                    placeholder="Enter Phone Number"
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={12} md={5.5}>
                  <MuiTelInput
                    fullWidth
                    label="Secondary Contact"
                    defaultCountry="MY"
                    size="small"
                    value={secondaryContact}
                    required
                    placeholder="Enter secondary Phone Number"
                    onChange={(newPhone) => setsecondaryContact(newPhone)}
                  />
                </Grid>
                <Grid item xs={12} md={5.5}>
                  <TextField
                    fullWidth
                    name="plotnumber"
                    label="Plot Number"
                    size="small"
                    defaultValue={"12345678"}
                    value={
                      vendorValues?.plotnumber ? vendorValues?.plotnumber : ""
                    }
                    onChange={handleChangeVendorValues}
                    placeholder="Enter Plot Number"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <Iconify icon="gis:position-o" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={5.5}>
                  <TextField
                    fullWidth
                    name="address"
                    label="Address"
                    size="small"
                    defaultValue={".e.g vincet street"}
                    value={vendorValues?.address ? vendorValues?.address : ""}
                    onChange={handleChangeVendorValues}
                    placeholder="Enter Address"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <Iconify icon="gis:position-o" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={5.5}>
                  <TextField
                    fullWidth
                    name="city"
                    label="City"
                    size="small"
                    value={vendorValues?.city ? vendorValues?.city : ""}
                    onChange={handleChangeVendorValues}
                    placeholder="Enter City Name"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <Iconify icon="healthicons:city-outline" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={5.5}>
                  <TextField
                    fullWidth
                    required
                    name="state"
                    label="State"
                    size="small"
                    value={vendorValues?.state ? vendorValues?.state : ""}
                    onChange={handleChangeVendorValues}
                    placeholder="Enter State"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <Iconify icon="streamline:shopping-building-real-home-tower-building-house-estate" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={5.5}>
                  <TextField
                    fullWidth
                    name="country"
                    label="Country"
                    size="small"
                    value={vendorValues?.country ? vendorValues?.country : ""}
                    onChange={handleChangeVendorValues}
                    placeholder="Country Name"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <Iconify icon="solar:flag-linear" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={5.5}>
                  <TextField
                    fullWidth
                    name="zipCode"
                    label="Pincode / Zipcode"
                    size="small"
                    type="number"
                    value={vendorValues?.zipCode ? vendorValues?.zipCode : ""}
                    onChange={
                      vendorValues?.zipCode?.length >= 6
                        ? () => {}
                        : (e) => handleChangeVendorValues(e)
                    }
                    placeholder="Enter Zipcode"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <Iconify icon="ri:file-zip-line" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                {/* <Grid xs={12} md={5.5}>
                  <TextField
                    fullWidth
                    disabled
                    name="password"
                    label="Create Password"
                    size="small"
                    value={vendorValues?.password ? vendorValues?.password : ""}
                    onChange={handleChangeVendorValues}
                    placeholder="Create Password"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <Iconify icon="vaadin:password" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid> */}
              </Grid>

              {/* ============================== */}
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
                mb={1}
              >
                <Typography variant="h6">
                  Store Location on map / Google Map position
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  sx={{ mt: 2 }}
                  onClick={() => map.panTo(selectedLocation)}
                >
                  go to marked location
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  size="small"
                  sx={{ mt: 2 }}
                  startIcon={<Iconify icon="carbon:map" />}
                  onClick={() => {
                    if (selectedLocation) {
                      // Handle the selected location here
                      setConfirmLolcation(true);
                    } else {
                      alert("Please select a location on the map.");
                    }
                  }}
                >
                  confirm Location
                </Button>
              </Stack>
              <Box>
                <LoadScript googleMapsApiKey={googleMapApiKey}>
                  <GoogleMap
                    center={center}
                    zoom={18}
                    mapContainerStyle={{ width: "100%", height: "400px" }}
                    onLoad={(map) => setmap(map)}
                    onClick={handleMarkerClick}
                    draggable={false}
                  >
                    {selectedLocation && (
                      <Marker
                        position={selectedLocation}
                        title={`${vendorValues?.plotnumber} ${vendorValues?.address} ${vendorValues?.city} , ${vendorValues?.state} ${vendorValues?.country} , ${vendorValues?.zipCode}`}
                        icon={{
                          url: "./assets/icons/chipt_map_icon.svg",
                        }}
                      />
                    )}
                  </GoogleMap>
                </LoadScript>
              </Box>

              {/* ============================== */}
              <Stack mt={2} direction={"row"}>
                <Button
                  type="submit"
                  disabled={!confirmLolcation}
                  variant="contained"
                  color="primary"
                >
                  Setup Account
                </Button>
              </Stack>
            </CardContent>
          </Box>
        </Card>
      </Container>
      {/* <OtpModal
        open={generatePasswordOTPModalOpen}
        handleClose={handleToggler}
        otpTimeOut={60}
        otp={otp}
        handleConfirmOtp={handleConfirmOtp}
        setOtp={setOtp}
        handleResendOtp={handleResendOtp}
      /> */}
      <GeneratePassword
        open={generatePasswordUpdateModalOpen}
        // open={"true"}
        handleClose={handleTogglePasswordUpdateModal}
        handleUpdatePassword={handleUpdatePassword}
        passwordValues={passwordValues}
        setPasswordValues={setPasswordValues}
      />
    </>
  );
};

export default FirstLoginMainPage;
