import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
// @mui
import { useDispatch } from "react-redux";
import { styled } from "@mui/material/styles";
//
import axios from "axios";
import Header from "./header";
import Nav from "./nav";
import { openSnackbar } from "../../redux/action/defaultActions";
import { baseUrl } from "../../utils/apiUrl";
import { updateProfileData } from "../../redux/action/adminActions";

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const StyledRoot = styled("div")({
  display: "flex",
  minHeight: "100%",
  overflow: "hidden",
});

const Main = styled("div")(({ theme }) => ({
  flexGrow: 1,
  overflow: "auto",
  minHeight: "100%",
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up("lg")]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // updateProfileData

  const getProfileData = async () => {
    try {
      const res = await axios.get(`${baseUrl}/account/information`);

      dispatch(updateProfileData(res?.data?.user));
    } catch (error) {
      if (error.response.data.message) {
        dispatch(openSnackbar(error.response.data.message, "error"));
      } else if (error.data.message) {
        dispatch(openSnackbar(error.data.message, "error"));
      } else {
        dispatch(openSnackbar("something went wrong", "error"));
      }
    }
  };

  useEffect(() => {
    getProfileData();
  }, []);

  return (
    <StyledRoot>
      <Header onOpenNav={() => setOpen(true)} />

      <Nav openNav={open} onCloseNav={() => setOpen(false)} />

      <Main>
        <Outlet />
      </Main>
    </StyledRoot>
  );
}
