export const ActionTypes = {
  ADMIN_LOGIN: "ADMIN_LOGIN",

  FIRST_TIME_LOGIN: "FIRST_TIME_LOGIN",

  ADMIN_LOGOUT: "ADMIN_LOGOUT",
  ADMIN_AUTH: "ADMIN_AUTH",
  VENDOR_PROFILE_DATA: "VENDOR_PROFILE_DATA",

  OPEN_SNACKBAR: "OPEN_SNACKBAR",
  CLOSE_SNACKBAR: "CLOSE_SNACKBAR",

  SET_ISLOADING: "SET_OPEN_ISLOADING",

  MODAL_TOGGLER_OTP_TOGGLER: "MODAL_TOGGLER_OTP_TOGGLER",
  MODAL_TOGGLER_PASSWORD_CREATION: "MODAL_TOGGLER_PASSWORD_CREATION",
};
