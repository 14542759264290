import { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Snackbar } from "@mui/material";
import axios from "axios";

import { getMessaging, getToken, onMessage } from "firebase/messaging";

// routes
import Router from "./routes";
// theme
import ThemeProvider from "./theme";
// components
import { StyledChart } from "./components/chart";
import ScrollToTop from "./components/scroll-to-top";
import Loader from "./components/loader/Loader";
import app from "./utils/firebase";
import { closeSnackbar } from "./redux/action/defaultActions";
import { baseUrl } from "./utils/apiUrl";

// ----------------------------------------------------------------------

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.withCredentials = true;
export default function App() {
  const messaging = getMessaging(app);

  const nfcTngVendor = useSelector((state) => state.nfcTngVendor);
  const snackbar = useSelector((state) => state.snackbar);

  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(closeSnackbar());
  };

  // useEffect(() => {
  //   Notification.requestPermission().then((permission) => {
  //     if (permission === "granted") {
  //       console.log("Notification permission granted.");
  //       getToken(messaging, {
  //         vapidKey:
  //           "BJMqAYWez45C4emuDXIzBFWZaQ479JMAtdOVDI_TCz6jK5ai8mvqCX9pFVPsXIdje_Rc45Y6ozcwSPdHBuS2Vh8",
  //       })
  //         .then((currentToken) => {
  //           getNotification(currentToken);
  //           console.log(currentToken);
  //         })
  //         .catch((error) => {
  //           console.log({ error });
  //         });
  //     }
  //   });
  // }, []);

  // ------------------------------------------------------------
  // const getNotification = async (notificationToken) => {
  //   try {
  //     console.log("token", notificationToken);
  //     const res = await axios.post(`${baseUrl}/account/notification-token`, {
  //       notificationToken,
  //     });
  //     console.log("response...", res);
  //   } catch (error) {
  //     console.log(error.message);
  //   }
  // };

  // onMessage(messaging, (payload) => {
  //   console.log("Message received. ", payload);
  //   console.log(payload.notification.body);
  //   new Notification(payload.notification.title, {
  //     body: payload.notification.body,
  //     icon: "./img/goodday.png",
  //   });
  //   // ...
  // });
  // ------------------------------------------------------------

  return (
    <>
      <Snackbar
        open={snackbar?.open}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleClose}
          variant="filled"
          severity={snackbar?.severity}
          sx={{ width: "100%" }}
        >
          {snackbar?.message}
        </Alert>
      </Snackbar>
      <Loader />
      <HelmetProvider>
        <BrowserRouter>
          <ThemeProvider>
            <ScrollToTop />
            <StyledChart />
            <Router />
          </ThemeProvider>
        </BrowserRouter>
      </HelmetProvider>
    </>
  );
}
