import { faker } from "@faker-js/faker";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";


import { useNavigate } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useDispatch } from "react-redux";

import axios from "axios";
import { AppWebsiteVisits } from "../sections/@dashboard/app";


import { isLoading, openSnackbar } from "../redux/action/defaultActions";
import { baseUrl } from "../utils/apiUrl";

const AllUsers = () => {
  const dispatch = useDispatch();

  const [allCustomers, setAllCustomers] = useState([]);

  const date = new Date();

  const navigate = useNavigate();

  const getAllCustomers = async () => {
    dispatch(isLoading(true));
    try {
      const res = await axios.get(`${baseUrl}/customer/all`);
      setAllCustomers(res?.data?.customers);
     
      dispatch(isLoading(false));
    } catch (error) {
      if (error.response.data.message) {
        dispatch(openSnackbar(error.response.data.message, "error"));
      } else {
        dispatch(openSnackbar("something went wrong", "error"));
      }
      dispatch(isLoading(false));
    }
  };

  const columns = [
    {
      field: "index",
      headerName: "ID",
      width: 50,
      // renderCell: (index) => index.api.getRowIndex(index._id),
      // renderCell: (params) => params.rowIndex + 1,
    },
    {
      field: "name",
      width: 250,
      description: "No description",
      // editable: true,
      renderCell: (params) => {
        return (
          <Stack direction={"row"} alignItems={"center"} spacing={1.5}>
            <Avatar
              alt={params?.row?.firstname}
              src={params?.row?.profilePicture?.url}
            />
            <Typography
              variant="h6"
              color={"primary"}
              fontFamily={"monospace"}
              component={"span"}
            >
              {`${params?.row?.firstname ? params?.row?.firstname : "-"} ${
                params?.row?.middlename ? params?.row?.middlename : ""
              } ${params?.row?.lastname ? params?.row?.lastname : ""}`}
            </Typography>
          </Stack>
        );
      },
    },
    {
      field: "primaryEmail",
      headerName: "Email",
      width: 220,
      editable: true,
    },
    {
      field: "primaryContactNumber",
      headerName: "Contact",
      width: 150,
      editable: true,
      renderCell: (params) =>
        `(+${params?.row?.countryCode}) ${params?.row?.primaryContactNumber}`,
    },
    {
      field: "address",
      headerName: "Address",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 550,
      renderCell: (params) => {
        return (
          <Typography
            component={"p"}
            variant="body1"
            sx={{ wordWrap: "wrap", overflow: "auto" }}
          >
            {`${params?.row?.address ? params?.row?.address : "-"} ${
              params?.row?.city ? params?.row?.city : "-"
            } ${params?.row?.state ? params?.row?.state : "-"}  ${
              params?.row?.country ? params?.row?.country : "-"
            } , ${params?.row?.zipCode ? params?.row?.zipCode : "-"} `}
          </Typography>
        );
      },
    },
  ];

  function CustomizedToolbarLayout() {
    return (
      <Grid container alignItems={"center"}>
        <Grid item xs={6}>
          <Box width="100%" sx={{ p: 2 }}>
            <GridToolbarQuickFilter
              fullWidth
              variant="outlined"
              size="small"
              sx={{
                "& .MuiOutlinedInput-root": {
                  color: "rgba(158, 158, 158, 1)",
                  borderRadius: "10px",
                  borderColor: "rgba(158, 158, 158, 1)",
                },
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={6} display={"flex"} justifyContent={"end"}>
          <CustomToolbar />
        </Grid>
      </Grid>
    );
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        {/* <GridToolbarColumnsButton /> */}
        <GridToolbarFilterButton />
        {/* <GridToolbarDensitySelector /> */}
        {/* <GridToolbarExport /> */}
      </GridToolbarContainer>
    );
  }

  const rowsWithIndex = allCustomers?.map((row, index) => ({
    ...row,
    index: index + 1,
  }));


  useEffect(() => {
    getAllCustomers();
  }, []);

  return (
    <>
      <Helmet>
        <title> Dashboard: User | NFC | CHIPT </title>
      </Helmet>

      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={2}
        >
          <Typography variant="h4" gutterBottom>
            All Users
          </Typography>
          <Stack
            direction={"row"}
            spacing={1}
            sx={{ cursor: "pointer", "&>hover": { color: "red" } }}
            mb={1}
            onClick={() => navigate(-1)}
          >
            <KeyboardBackspaceIcon />{" "}
            <Typography variant="body2" component={"span"}>
              Back
            </Typography>
          </Stack>
        </Stack>
        <Grid container spacing={2} mt={2} justifyContent={"space-between"}>
          <Grid item xs={12} md={8}>
            <AppWebsiteVisits
              title="Newly Added Customers"
              subheader="Customers being connected"
              chartLabels={[
                "02/01/2023",
                "03/02/2023",
                "04/03/2023",
                "05/04/2023",
                "06/05/2023",
                "07/06/2023",
                "08/07/2023",
                "09/07/2023",
                "10/08/2023",
                "11/09/2023",
              ]}
              chartData={[
                {
                  name: "New Customers",
                  type: "column",
                  fill: "solid",
                  data: [30, 32, 36, 31, 31, 25, 37, 30, 44, 28, 30],
                },
                // {
                //   name: "Active",
                //   type: "area",
                //   fill: "gradient",
                //   data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43],
                // },
                // {
                //   name: "Returned",
                //   type: "line",
                //   fill: "solid",
                //   data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39],
                // },
              ]}
            />
          </Grid>
          {/* <Grid item xs={12} md={3.5}>
            <Card>
              <CardHeader title={"Recently Added"} />
              <CardContent
                sx={{
                  "& .MuiTimelineItem-missingOppositeContent:before": {
                    display: "none",
                  },
                }}
              >
                <Timeline>
                  {customers?.map((customer, index) => {
                    return (
                      <TimelineItem>
                        <TimelineSeparator>
                          <TimelineDot
                            color={
                              (index === 1 && "primary") ||
                              (index === 2 && "success") ||
                              (index === 3 && "info") ||
                              (index === 4 && "warning") ||
                              "error"
                            }
                          />
                          {index === customers?.length - 1 ? null : (
                            <TimelineConnector />
                          )}
                        </TimelineSeparator>
                        <TimelineContent>
                          <Typography variant="subtitle2">{`${customer?.firstname} ${customer?.lastname}`}</Typography>

                          <Typography
                            variant="caption"
                            sx={{ color: "text.secondary" }}
                          >
                            {fDateTime(date)}
                          </Typography>
                        </TimelineContent>
                      </TimelineItem>
                    );
                  })}
                </Timeline>
              </CardContent>
            </Card>
          </Grid> */}
        </Grid>
        <Grid container spacing={2} mt={2} justifyContent={"space-between"}>
          <Grid item xs={12} md={12}>
            <Box sx={{ height: 400, width: "100%" }}>
              <DataGrid
                rows={rowsWithIndex.length > 0 ? rowsWithIndex : []}
                columns={columns}
                slots={{ toolbar: CustomizedToolbarLayout }}
                // disableColumnFilter
                disableColumnSelector
                getRowId={(row) => row?._id}
                sx={{
                  background: "white",
                  boxShadow: "4px 7px 20px 0px #e1d7d78f",
                  p: 1,
                }}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 5,
                    },
                  },
                }}
                pageSizeOptions={[5]}
                // checkboxSelection
                disableRowSelectionOnClick
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default AllUsers;
