// component
import SvgColor from "../../../components/svg-color";

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor
    src={`/chiptvendor/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const navConfig = [
  {
    title: "dashboard",
    path: "/app",
    icon: icon("ic_analytics"),
  },
  {
    title: "recieve cups",
    path: "/recieve",
    icon: icon("ic_nfcscan"),
  },
  {
    title: "inventory",
    path: "/inventory",
    icon: icon("ic_inventorymug"),
  },
  

  //   {
  //     title: "coffee gallery",
  //     path: "/coffee",
  //     icon: icon("ic_coffee_inventory"),
  // },
  {
    title: "returned",
    path: "/returned",
    icon: icon("ic_returned1"),
  },
  {
    title: "place order",
    path: "/place/order",
    icon: icon("ic_request"),
  },
  {
    title: "orders",
    path: "/orders",
    icon: icon("ic_order"),
  },
  {
    title: "location",
    path: "/location",
    icon: icon("ic_location"),
  },
  // {
  //   title: "feedback",
  //   path: "/feedback",
  //   icon: icon("ic_feeback"),
  // },
  // {
  //     title: 'scan',
  //     path: '/scan',
  //     icon: icon('ic_disabled'),
  //   },
  // {
  //   title: 'login',
  //   path: '/login',
  //   icon: icon('ic_lock'),
  // },
  // {
  //   title: 'logout',
  //   path: '/login',
  //   icon: icon('ic_signout'),
  // },
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: icon('ic_disabled'),
  // },
];

export default navConfig;
