import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Paper,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { fDateTime } from "../../../utils/formatTime";

const OrderInfoCard = ({
  stepsCompleted,
  orderDetails,
  approvalStatus,
  requestStatus,
  isDeliveryConfirm,
}) => {
  // console.log("details==>", orderDetails);
  const navigate = useNavigate();
  const steps = [
    "order placed",
    "confirmed",
    "pending to deliver",
    "delivered",
  ];
  const isStepFailed = (step) => {
    return step === 1;
  };

  return (
    <>
      <Card sx={{ position: "relative" }}>
        <Typography
          variant="body2"
          component={"span"}
          sx={{ position: "absolute", right: 15, top: 10 }}
        >
          Order Date : {fDateTime(orderDetails?.dateOfRequest)}
        </Typography>
        <CardHeader title={"OrderId"} subheader={orderDetails?._id} />
        <CardContent>
          <Box sx={{ width: "100%" }}>
            <Stepper
              activeStep={
                approvalStatus === "STATUS_ACCEPTED" &&
                requestStatus !== "DELIVERED"
                  ? 2
                  : requestStatus === "DELIVERED"
                  ? 3
                  : requestStatus === "DELIVERED" && isDeliveryConfirm === true
                  ? 4
                  : 0
              }
              sx={{ maxHeight: { xs: "220px", sm: "100%" }, overflow: "auto" }}
            >
              {steps?.map((label, index) => {
                const labelProps = {};
                if (approvalStatus === "STATUS_REJECTED" && index !== 0) {
                  isStepFailed(1);
                  labelProps.optional = (
                    <Typography variant="caption" color="error">
                      Rejected
                    </Typography>
                  );
                  labelProps.error = true;
                }
                return (
                  <Step key={label}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            {/* {stepsCompleted >= 3 ? ( */}
            <Stack mt={2} mb={2} alignItems={"start"}>
              <Button
                variant="outlined"
                onClick={() =>
                  navigate(`/orders/confirm/order/${orderDetails?._id}`)
                }
              >
                View Details
              </Button>
            </Stack>
            {/* ) : (
              ""
            )} */}
          </Box>
          <Stack mt={2}>
            <Typography mb={2}>Order Items</Typography>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Index</TableCell>
                    <TableCell align="left">Cup Chipt Id</TableCell>
                    <TableCell align="right">Item Type</TableCell>
                    <TableCell align="right">Item Category</TableCell>
                    <TableCell align="right">Item Quantity</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orderDetails?.cupsRequested?.map((item, index) => (
                    <TableRow
                      key={index}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {index + 1}
                      </TableCell>

                      <TableCell align="left">
                        {item?.cupModelUniqueId}
                      </TableCell>
                      <TableCell align="right">{item?.cupType}</TableCell>
                      <TableCell align="right">{item?.cupSize}</TableCell>
                      <TableCell align="right">{item?.numberOfCups}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Stack>
        </CardContent>
      </Card>
    </>
  );
};

export default OrderInfoCard;
