import React, { useState } from "react";
import {
  Backdrop,
  Box,
  Button,
  Container,
  Fade,
  IconButton,
  InputAdornment,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import { useDispatch } from "react-redux";

import { useNavigate } from "react-router-dom";

import axios from "axios";

import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

import { baseUrl } from "../../../utils/apiUrl";

import Iconify from "../../../components/iconify";

import { isLoading, openSnackbar } from "../../../redux/action/defaultActions";

const UpdatePasswordFirstTimeLogin = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  const [passwordValues, setPasswordValues] = useState({});

  const [vendorValues, setVendorValues] = useState({});

  const [passwordCompared, setPasswordCompared] = useState({
    checked: 0,
    message: "",
  });
  //   state to check compared password

  const handleChange = (e) =>
    setPasswordValues({ ...passwordValues, [e.target.name]: e.target.value });
  // === handle compare password ===
  const handleComparePassword = () => {
    if (
      passwordValues?.password.length > 0 &&
      passwordValues?.password === passwordValues?.confirmPassword
    ) {
      setPasswordCompared({
        checked: 1,
        message: "Confirm password mathced !",
      });
    } else if (passwordValues?.password.length <= 0) {
      setPasswordCompared({
        checked: -1,
        message: "Please Enter A Password",
      });
    } else {
      setPasswordCompared({
        checked: -1,
        message: "Confirm password doesn't match !",
      });
    }
  };

  // update password
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("passoword==>", passwordValues);
    dispatch(isLoading(true));

    try {
      const res = await axios.put(`${baseUrl}/account/password/change`, {
        oldpassword: passwordValues?.oneTimePassword,
        newpassword: passwordValues?.password,
        confirmpassword: passwordValues?.confirmPassword,
      });
      

      dispatch(isLoading(false));
      
      navigate("/create-profile");
    } catch (error) {
     
      if (error.response.data.message) {
        dispatch(openSnackbar(error.response.data.message, "error"));
      } else {
        dispatch(
          openSnackbar("something went wrong , please try againg", "error")
        );
      }
      dispatch(isLoading(false));
      console.log({ error });
    }
  };

  return (
    <>
      <Container>
        <Box
          display={"flex"}
          flexDirection={"row"}
          alignContent={"center"}
          justifyContent={"center"}
          height={"100vh"}
        >
          <Box
            width={{ md: "40%", sm: "70%", xs: "100%" }}
            alignSelf={"center"}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              mb={2}
            >
              <Stack
                direction={"row"}
                spacing={1}
                sx={{ cursor: "pointer", "&>hover": { color: "red" } }}
                mb={1}
                onClick={() => navigate(-1)}
              >
                <KeyboardBackspaceIcon />{" "}
                <Typography variant="body2" component={"span"}>
                  Back
                </Typography>
              </Stack>
            </Stack>
            <Stack direction={"row"}>
              <Typography
                color={"primary"}
                sx={{ textTransform: "uppercase" }}
                variant="h6"
                component="h2"
              >
                Create Password
              </Typography>
            </Stack>
            <Stack
              component={"form"}
              onSubmit={handleSubmit}
              mt={1}
              spacing={2}
            >
              <TextField
                type="password"
                name="oneTimePassword"
                value={
                  passwordValues?.oneTimePassword
                    ? passwordValues?.oneTimePassword
                    : ""
                }
                label="One Time Password"
                required
                size="small"
                onChange={handleChange}
              />
              <TextField
                type="password"
                name="password"
                value={passwordValues?.password ? passwordValues?.password : ""}
                label="New Password"
                required
                size="small"
                onChange={handleChange}
              />
              <TextField
                size="small"
                name="confirmPassword"
                value={
                  passwordValues?.confirmPassword
                    ? passwordValues?.confirmPassword
                    : ""
                }
                helperText={`*${passwordCompared?.message}`}
                sx={{
                  "&>.MuiFormHelperText-root": {
                    color:
                      passwordCompared?.checked === 1
                        ? "green"
                        : passwordCompared?.checked === -1
                        ? "red"
                        : "grey",
                  },
                }}
                required
                label="Confirm password"
                type={showPassword ? "text" : "password"}
                // solar:eye-bold-duotone

                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start" sx={{ fontSize: "20px" }}>
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        <Iconify
                          icon={
                            showPassword ? "eva:eye-fill" : "eva:eye-off-fill"
                          }
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                onChange={handleChange}
                onBlur={handleComparePassword}
              />

              <Stack>
                <Button type="submit" variant="contained" color="primary">
                  Generate Password
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default UpdatePasswordFirstTimeLogin;
