import React, { useState } from "react";
import {
  Container,
  Box,
  Stack,
  Typography,
  TextField,
  Button,
} from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../../utils/apiUrl";
import { isLoading, openSnackbar } from "../../redux/action/defaultActions";

const UpdatePassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [passwordValues, setPasswordValues] = useState({});
  const { state } = useLocation();

  //
  const handleChangePasswordValues = (e) =>
    setPasswordValues({ ...passwordValues, [e.target.name]: e.target.value });
  
  //   reset password
  const handleResetPassword = async (e) => {
    e.preventDefault();
    dispatch(isLoading(true));
    try {
      const res = await axios.put(`${baseUrl}/account/otp/reset/password`, {
        email: state?.email,
        newPassword: passwordValues?.newPassword,
        confirmPassword: passwordValues?.confirmPassword,
      });
      dispatch(openSnackbar("password updated successfully", "success"));
      dispatch(isLoading(false));
      navigate("/login");
    } catch (error) {
      if (error?.response?.data?.message) {
        dispatch(openSnackbar(error?.response?.data?.message, "error"));
      } else {
        dispatch(openSnackbar("something went wrong", "error"));
      }
      dispatch(isLoading(false));
    }
  };
  return (
    <>
      <Container>
        <Box
          display={"flex"}
          flexDirection={"row"}
          alignContent={"center"}
          justifyContent={"center"}
          height={"100vh"}
        >
          <Box
            width={{ md: "40%", sm: "70%", xs: "100%" }}
            alignSelf={"center"}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              mb={2}
            >
              <Stack
                direction={"row"}
                spacing={1}
                sx={{ cursor: "pointer", "&>hover": { color: "red" } }}
                mb={1}
                onClick={() => navigate(-1)}
              >
                <KeyboardBackspaceIcon />{" "}
                <Typography variant="body2" component={"span"}>
                  Back
                </Typography>
              </Stack>
            </Stack>
            <Stack direction={"row"}>
              <Typography color={"primary"} variant="h6" component="h2">
                Reset Password
              </Typography>
            </Stack>
            <Typography>
              After resetting password you will be redirect to the login page,
              You will have to provide the updated password at the time of
              login.
            </Typography>
            <Stack
              component={"form"}
              mt={2}
              onSubmit={handleResetPassword}
              spacing={2}
            >
              <TextField
                name="newPassword"
                required
                label="New Password"
                value={passwordValues?.newPassword}
                onChange={handleChangePasswordValues}
                placeholder="Create Password"
                size="small"
              />
              <TextField
                name="confirmPassword"
                required
                label="Confirm Password"
                value={passwordValues?.confirmPassword}
                onChange={handleChangePasswordValues}
                placeholder="Confirm Password"
                size="small"
              />
              <Button variant="contained" type="submit" color="primary">
                Reset Password
              </Button>
            </Stack>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default UpdatePassword;
