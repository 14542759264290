import * as React from "react";
import Backdrop from "@mui/material/Backdrop";

import {  useSelector } from "react-redux";
import "../../index.css"
// D:\TECHDOCKLABS\nfc-admin-theme\src\index.css
export default function Loader() {
  const  loading  = useSelector((state) => state.loading);

  return (
    <div>
      <Backdrop
        sx={{
          color: "#208808",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          background:"rgba(0,0,0,0.8)"
        }}
        open={loading?.open}
      >
        {/* <CircularProgress color="inherit" /> */}
        <div className="container">
          <div className="loader">
            <div className="loader--dot"/>
            <div className="loader--dot"/>
            <div className="loader--dot"/>
            <div className="loader--dot"/>
            <div className="loader--dot"/>
            <div className="loader--dot"/>
            <div className="loader--text"/>
          </div>
        </div>
      </Backdrop>
    </div>
  );
}
