import { Box, Container, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";

import { useDispatch } from "react-redux";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import axios from "axios";
import OrderInfoCard from "./OrderInfoCard";
import { isLoading } from "../../../redux/action/defaultActions";
import { baseUrl } from "../../../utils/apiUrl";

const OrdersMain = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [allOrderDetails, setAllOrderDetails] = useState([]);
  // ====== get orders ======
  const getAllOrdersDetails = async () => {
    dispatch(isLoading(true));
    try {
      const response = await axios.get(`${baseUrl}/store/stock/requests`);
      // console.log({ response });
      setAllOrderDetails(response?.data?.stockRequest);
      dispatch(isLoading(false));
    } catch (error) {
      console.log({ error });
      dispatch(isLoading(false));
    }
  };
  // ========================
  useEffect(() => {
    getAllOrdersDetails();
  }, []);

  // ========================
  return (
    <>
      <Helmet>
        <title> Dashboard : Order | Cups | CHIPT </title>
      </Helmet>

      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={2}
        >
          <Typography variant="h4" gutterBottom>
            All Placed Orders
          </Typography>
          <Stack
            direction={"row"}
            spacing={1}
            sx={{ cursor: "pointer", "&>hover": { color: "red" } }}
            mb={1}
            onClick={() => navigate(-1)}
          >
            <KeyboardBackspaceIcon />{" "}
            <Typography variant="body2" component={"span"}>
              Back
            </Typography>
          </Stack>
        </Stack>
        <Box
          sx={{
            height: "100%",
            maxHeight: { md: "650px", sm: "400px" },
            overflow: "auto",
            "&::-webkit-scrollbar": {
              width: "12px",
              //   bgcolor: "#757de8",
              //   bgcolor: "lightgrey",
              bgcolor: "transparent",

              borderRadius: "6px",
            },
            "&::-webkit-scrollbar-thumb": {
              //   bgcolor: "#002984 !important",
              //   bgcolor: "#2065D1",
              bgcolor: "transparent",
              width: "50%",
              height: "1%",
              borderRadius: "6px",
            },
          }}
        >
          <Stack spacing={3}>
            {allOrderDetails && allOrderDetails?.length > 0 ? (
              allOrderDetails?.map((order, index) => {
                // console.log("==>", order);
                return (
                  <OrderInfoCard
                    key={index}
                    stepsCompleted={order}
                    approvalStatus={order?.approvalStatus}
                    requestStatus={order?.requestStatus}
                    orderDetails={order}
                    isDeliveryConfirm={order?.isDeliveryConfirm}
                  />
                );
              })
            ) : (
              <>
                <Typography variant="body2" color={"error"} component={"p"}>
                  There are no transactions found for your orders! Start
                  Transactions ,made request for stock orders.
                </Typography>
                <Stack mt={2} alignItems={"center"}>
                  <img
                    src="./assets/icons/nodata.gif"
                    alt="no data found"
                    // width={"200px"}
                    style={{ maxHeight: "280px" }}
                  />
                </Stack>
              </>
            )}
            {/* <OrderInfoCard stepsCompleted={3} />
            <OrderInfoCard stepsCompleted={4} />
            <OrderInfoCard stepsCompleted={2} /> */}
          </Stack>
        </Box>
      </Container>
    </>
  );
};

export default OrdersMain;
