import React, { useEffect, useState } from "react";
import { Stack, Typography, Button, Box, Container, Grid } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useDispatch, useSelector } from "react-redux";
import {
  GoogleMap,
  InfoWindow,
  InfoWindowF,
  LoadScript,
  Marker,
} from "@react-google-maps/api";
import { useNavigate } from "react-router-dom";

import axios from "axios";

import { Helmet } from "react-helmet-async";

import { baseUrl } from "../../../utils/apiUrl";

import { googleMapApiKey } from "../../../utils/keys";

import Iconify from "../../../components/iconify";

import { isLoading, openSnackbar } from "../../../redux/action/defaultActions";

const StoreLocation = () => {
  const { nfcTngVendor } = useSelector((state) => state);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [selectedLocation, setSelectedLocation] = useState(null);

  const [confirmLolcation, setConfirmLolcation] = useState(false);

  const [map, setmap] = useState(null);

  // Initial position for the map
  const center = {
    lat: 26.7991202,
    lng: 80.8854058,
  };

  const handleMarkerClick = (event) => {
    setSelectedLocation({
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    });
    setConfirmLolcation(false);
  };

  // update location
  const handleUpdateLocation = async (e) => {
    e.preventDefault();
    dispatch(isLoading(true));
    try {
      const res = await axios.put(`${baseUrl}/account/store/location/update`, {
        location: [selectedLocation?.lat, selectedLocation?.lng],
      });
      dispatch(openSnackbar("location updated successfully", "success"));
      dispatch(isLoading(false));
      navigate("/profile");
    } catch (error) {
      console.log("error=>", error);
      if (error.response.data.message) {
        dispatch(openSnackbar(error.response.data.message, "error"));
      } else {
        dispatch(openSnackbar("something went wrong", "error"));
      }
      dispatch(isLoading(false));
    }
  };

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      setSelectedLocation({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });
    });
    if (map && selectedLocation) {
      (() => {
        // Function Body...
        map.panTo(selectedLocation);
      })();
    }
  }, []);

  return (
    <>
      <Helmet>
        <title> Dashboard : Upload Location | CHIPT </title>
      </Helmet>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={2}
        >
          <Typography variant="h4" gutterBottom>
            Update Location
          </Typography>
          <Stack
            direction={"row"}
            spacing={1}
            sx={{ cursor: "pointer", "&>hover": { color: "red" } }}
            mb={1}
            onClick={() => navigate(-1)}
          >
            <KeyboardBackspaceIcon />{" "}
            <Typography variant="body2" component={"span"}>
              Back
            </Typography>
          </Stack>
        </Stack>

        <form onSubmit={handleUpdateLocation}>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            mb={1}
          >
            <Grid container rowGap={2} columnGap={2}>
              <Grid
                item
                xs={12}
                md={5.75}
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Typography variant="h6">
                  Store Location on map / Google Map position
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={5.75}
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  sx={{ mt: 2 }}
                  onClick={() => map.panTo(selectedLocation)}
                >
                  go to marked location
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  size="small"
                  sx={{ mt: 2 }}
                  startIcon={<Iconify icon="carbon:map" />}
                  onClick={() => {
                    if (selectedLocation) {
                      // Handle the selected location here
                      setConfirmLolcation(true);
                    } else {
                      alert("Please select a location on the map.");
                    }
                  }}
                >
                  confirm Location
                </Button>
              </Grid>
            </Grid>
          </Stack>
          <Box mt={1}>
            <LoadScript googleMapsApiKey={googleMapApiKey}>
              <GoogleMap
                center={center}
                zoom={18}
                mapContainerStyle={{ width: "100%", height: "400px" }}
                onLoad={(map) => setmap(map)}
                onClick={handleMarkerClick}
                draggable={false}
              >
                {selectedLocation && (
                  <Marker
                    position={selectedLocation}
                    // title={`${vendorValues?.plotnumber} ${vendorValues?.address} ${vendorValues?.city} , ${vendorValues?.state} ${vendorValues?.country} , ${vendorValues?.zipCode}`}
                    icon={{
                      url: "./assets/icons/chipt_map_icon.svg",
                    }}
                  />
                )}
              </GoogleMap>
            </LoadScript>
          </Box>
          <Stack mt={2} direction={"row"}>
            <Button
              disabled={!confirmLolcation}
              type="submit"
              variant="contained"
              color="primary"
            >
              Update Location
            </Button>
          </Stack>
        </form>
      </Container>
    </>
  );
};

export default StoreLocation;
