import { Box, Button, Modal, Stack, Typography } from "@mui/material";
import React from "react";

const ConfirmActionModal = ({ open, handleClose, handleAction }) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    // border: "2px solid primary.dark",
    boxShadow: 24,
    p: 4,
  };
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Are you sure ? Want to confirm this!
          </Typography>
          <Stack mt={2} direction={"row"} alignItems={"center"} spacing={2}>
            <Button variant="contained" color="inherit" onClick={handleClose}>
              Close
            </Button>
            <Button variant="contained" color="primary" onClick={handleAction}>
              confirm
            </Button>
          </Stack>
        </Box>
      </Modal>
    </>
  );
};

export default ConfirmActionModal;
